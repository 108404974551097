import Highlights from '../templates/highlights.handlebars';
import Index from '../templates/index.handlebars';
import Splash from '../templates/splash.handlebars';
import Directors from '../templates/directors.handlebars';
import Content from '../templates/content.handlebars';
import Tabs from '../templates/tabs.handlebars';
import MultiList from '../templates/multi-list.handlebars';

const templates = {
    highlights: Highlights,
    index: Index,
    splash: Splash,
    directors: Directors,
    content: Content,
    tabs: Tabs,
    multilist: MultiList

};

export default templates;