import Handlebars from 'handlebars/runtime';

Handlebars.registerHelper('isCommittee', (allCommittees, myCommittees, theme, cssObject) => {
    theme = typeof theme === "undefined" ? "light" : theme === "" ? "light" : theme;

    const textColor = !cssObject[theme] || !cssObject[theme].chairColor ? "inherit" : cssObject[theme].chairColor === "" ? "inherit" : cssObject[theme].chairColor;
    const dotColor = !cssObject[theme] || !cssObject[theme].committeeDotColor ? "inherit" : cssObject[theme].committeeDotColor === "" ? "inherit" : cssObject[theme].committeeDotColor;

    const tableRow = [];
    const myCommitteeArr = myCommittees.map(committee => committee.name);

    allCommittees.forEach((committee) => {

        const idx = myCommitteeArr.indexOf(committee);
        if (idx > -1) {
            const isText = myCommittees && myCommittees[idx] && myCommittees[idx].text || false;
            if (isText) {
                tableRow.push('<td class="table-page_column"><span class="table_chair" style="color: ' + textColor + ';">' + myCommittees[idx].text + '</span></td>');
            } else {
                tableRow.push('<td class="table-page_column"><span class="table_dot" style="background-color: ' + dotColor + ';"></span></td>');
            }
        } else {
            tableRow.push('<td class="table-page_column"></td>');
        }
    });

    return new Handlebars.SafeString(tableRow.join(''));
});

Handlebars.registerHelper('sectionTheme', (theme) => {
    theme = theme || 'light';
    return theme;
});

Handlebars.registerHelper('excludeBoard', (committees, options) => {
    let results = "";

    $.each(committees, function (i, item) {
        if (item.name.toLowerCase().indexOf('board') > -1) {
            return;
        } else results += options.fn(item);
    });

    return results;
});

Handlebars.registerHelper('nonBoard', (committees, options) => {
    return committees.length <= 1 && committees[0].name.toLowerCase().indexOf('board') > -1 ? options.inverse(this) : options.fn(this);
});

Handlebars.registerHelper('ifCondition', function(string, prop, options) {
    return string === prop ? options.fn(this) : options.inverse(this);
});

Handlebars.registerHelper('hasString', function(string, prop, options) {
    return prop.indexOf(string) > -1 ? options.fn(this) : options.inverse(this);
});