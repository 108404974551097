var Handlebars = require("/home/rof/src/github.com/q4mobile/interactive-proxy/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " navigation--desktop";
},"3":function(container,depth0,helpers,partials,data) {
    return " navigation--mobile";
},"5":function(container,depth0,helpers,partials,data) {
    var helper;

  return "navigation--"
    + container.escapeExpression(((helper = (helper = helpers.theme || (depth0 != null ? depth0.theme : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"theme","hash":{},"data":data}) : helper)));
},"7":function(container,depth0,helpers,partials,data) {
    return "2pt";
},"9":function(container,depth0,helpers,partials,data) {
    return "1pt";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <div class=\"navigation_container\" "
    + container.escapeExpression((helpers.style || (depth0 && depth0.style) || helpers.helperMissing).call(alias1,"color","textColor",(depth0 != null ? depth0.theme : depth0),(depth0 != null ? depth0.defaultCss : depth0),null,(depth0 != null ? depth0.css : depth0),{"name":"style","hash":{},"data":data}))
    + ">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + container.escapeExpression((helpers.htmlParser || (depth0 && depth0.htmlParser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,(depth0 != null ? depth0.theme : depth0),((stack1 = (data && data.root)) && stack1.defaultCss),{"name":"htmlParser","hash":{},"data":data}))
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"navigation"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.desktop : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.mobile : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.theme : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " fp-normal-scroll\">\n    <div role=\"button\" tabindex=\"0\" class=\"navigation_button q4proxy-hamburger-q4inc-"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.desktop : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + "\"></div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <div class=\"navigation_overlay\"></div>\n</div>";
},"useData":true});