var Handlebars = require("/home/rof/src/github.com/q4mobile/interactive-proxy/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                    <div class=\"splash-page_logo-container\">\n                        <div class=\"splash-page_logo\">\n                            <a href=\""
    + alias4(((helper = (helper = helpers.logoLink || (depth0 != null ? depth0.logoLink : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"logoLink","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\"><img src=\""
    + alias4(((helper = (helper = helpers.logo || (depth0 != null ? depth0.logo : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"logo","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = helpers.companyName || (depth0 != null ? depth0.companyName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"companyName","hash":{},"data":data}) : helper)))
    + " Logo\"/></a>\n                        </div>\n                    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "<p class=\"splash-page_heading\">"
    + ((stack1 = ((helper = (helper = helpers.heading || (depth0 != null ? depth0.heading : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"heading","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "<p class=\"splash-page_subtitle\">"
    + ((stack1 = ((helper = (helper = helpers.subtitle || (depth0 != null ? depth0.subtitle : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"subtitle","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>";
},"7":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                <div class=\"splash-page_footer\">\n                    <div class=\"splash-page_download-container\">\n                        <a href=\""
    + container.escapeExpression(((helper = (helper = helpers.downloadUrl || (depth0 != null ? depth0.downloadUrl : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"downloadUrl","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\" class=\"splash-page_download-link\"><span\n                                class=\"splash-page_icon q4proxy-pdf-2pt\"></span> Download</a>\n                    </div>\n                </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div "
    + alias3((helpers.parentClass || (depth0 && depth0.parentClass) || alias2).call(alias1,(depth0 != null ? depth0.template : depth0),(depth0 != null ? depth0.theme : depth0),(depth0 != null ? depth0.anchor : depth0),(depth0 != null ? depth0.className : depth0),(depth0 != null ? depth0.pageName : depth0),{"name":"parentClass","hash":{},"data":data}))
    + " "
    + alias3((helpers.style || (depth0 && depth0.style) || alias2).call(alias1,"color background-color","textColor templateBgColor",(depth0 != null ? depth0.theme : depth0),(depth0 != null ? depth0.defaultCss : depth0),null,(depth0 != null ? depth0.css : depth0),{"name":"style","hash":{},"data":data}))
    + ">\n    <div class=\"splash-page_wrapper\">\n        <div class=\"splash-page_container\">\n            <div class=\"splash-page_header\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.logo : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"splash-page_body\">\n                "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.heading : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                <h1 class=\"splash-page_title section_title\" "
    + alias3((helpers.style || (depth0 && depth0.style) || alias2).call(alias1,"color","titleColor",(depth0 != null ? depth0.theme : depth0),(depth0 != null ? depth0.defaultCss : depth0),null,null,{"name":"style","hash":{},"data":data}))
    + ">"
    + ((stack1 = ((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</h1>\n                "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.subtitle : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.downloadUrl : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>";
},"useData":true});