(function($) {
    $.fn.swipe = function( callback ) {
        let touchDown = false,
            originalPosition = null,
            $el = $( this );

        function swipeInfo( event ) {
            const x = event.pageX || event.touches[0].pageX;
            const y = event.pageY || event.touches[0].pageY;

            let deltaX, deltaY;

            deltaX = ( x > originalPosition.x ) ? "right" : "left";
            deltaY = ( y > originalPosition.y ) ? "down" : "up";

            return {
                direction: {
                    x: deltaX,
                    y: deltaY
                },
                offset: {
                    x: x - originalPosition.x,
                    y: originalPosition.y - y
                }
            };
        }

        $el.on( "touchstart mousedown", function ( event ) {
            touchDown = true;
            originalPosition = {
                x: event.pageX || event.touches[0].pageX,
                y: event.pageY || event.touches[0].pageY
            };
        } );

        $el.on( "touchend mouseup", function () {
            touchDown = false;
            originalPosition = null;
        } );

        $el.on( "touchmove mousemove", function ( event ) {
            if ( !touchDown ) { return;}
            const info = swipeInfo( event );
            callback( info.direction, info.offset, event);
        } );

        return true;
    };
})(jQuery);