var Handlebars = require("/home/rof/src/github.com/q4mobile/interactive-proxy/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "    <div class=\"language"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.layout : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" "
    + alias3((helpers.customStyle || (depth0 && depth0.customStyle) || alias2).call(alias1,(depth0 != null ? depth0.css : depth0),{"name":"customStyle","hash":{},"data":data}))
    + ">\n        <div class=\"language_current\">\n            <div class=\"language_active\">"
    + alias3(((helper = (helper = helpers["default"] || (depth0 != null ? depth0["default"] : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"default","hash":{},"data":data}) : helper)))
    + "</div>\n        </div>\n        <div class=\"language_selection\">\n            <ul class=\"language_list\" "
    + alias3((helpers.style || (depth0 && depth0.style) || alias2).call(alias1,"background-color","eventBgColor",(depth0 != null ? depth0.theme : depth0),(depth0 != null ? depth0.defaultCss : depth0),null,(depth0 != null ? depth0.css : depth0),{"name":"style","hash":{},"data":data}))
    + ">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </ul>\n        </div>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper;

  return " language--"
    + container.escapeExpression(((helper = (helper = helpers.layout || (depth0 != null ? depth0.layout : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"layout","hash":{},"data":data}) : helper)));
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                    <li class=\"language_list-item\">\n                        <a class=\"language_list-item-link\" href=\""
    + alias4(((helper = (helper = helpers.link || (depth0 != null ? depth0.link : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"link","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "</a>\n                    </li>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "    <div class=\"language"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.layout : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" "
    + container.escapeExpression((helpers.customStyle || (depth0 && depth0.customStyle) || helpers.helperMissing).call(alias1,(depth0 != null ? depth0.css : depth0),{"name":"customStyle","hash":{},"data":data}))
    + ">\n        <div class=\"language_selection\">\n            <ul class=\"language_list\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </ul>\n        </div>\n    </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return ((stack1 = (helpers.ifCondition || (depth0 && depth0.ifCondition) || alias2).call(alias1,"compressed",(depth0 != null ? depth0.layout : depth0),{"name":"ifCondition","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.ifCondition || (depth0 && depth0.ifCondition) || alias2).call(alias1,"extended",(depth0 != null ? depth0.layout : depth0),{"name":"ifCondition","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});