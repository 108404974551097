const Config = {
    // devFilePath: 'https://interactiveproxy.dev.q4inc.com/assets',
    devFilePath: '/assets',
    prodFilePath: '/assets',
    proxyData: './assets',
    responsiveWidth: 1280,
    responsiveHeight: 610,
    disableFullpageOn: 768,
    unsupportedBrowsers: /MSIE 8.0|MSIE 9.0|MSIE 10.0/,
    useNativeScroll: true,
    cookieRetention: 30,
    disableFullpageScrolling: false
};

module.exports = Config;