import Handlebars from 'handlebars/runtime';
import StyleString from "to-style";

/**
 * prop[string] - the css property. supports multiple properties; ie: `color`, `color background-color`
 * value[string] - the mapped value. supports multiple values; ie: `#fff` `#fff red`
 * theme[string] - which theme to map the value from; ie: `light`
 * css[object] - default css branding from JSON
 * width[string] - the static width of a container; ie; '25%'
 * custom[object] - custom inline css for a dom object
 */
Handlebars.registerHelper('style', (prop, value, theme, css, width, custom) => {
    const htmlString = StyleString.string(custom || {}) || '';
    const itemWidth = width ? `flex-basis:${width}; flex-shrink: 0;` : '';

    if (!prop || !css || !css[theme || 'light']) {
        return !htmlString || !itemWidth ? '' : new Handlebars.SafeString(`style="${itemWidth}${htmlString}"`);
    }

    const props = prop.split(' ');
    let inline = [];
    props.forEach((property, i) => {
        const cssObject = css[theme || 'light'];
        if (!cssObject || !cssObject[value.split(' ')[i]]) {
            return;
        } else {
            inline.push(`${property}:${cssObject[value.split(' ')[i]]};`);
        }
    });
    inline = inline.join(' ');
    return new Handlebars.SafeString(`style="${inline}${itemWidth}${htmlString}"`);
});

/**
 * custom[object] - custom inline css for a dom object
 */
Handlebars.registerHelper('customStyle', (custom) => {
    return !custom || Object.keys(custom).length === 0 ? '' : new Handlebars.SafeString(`style="${StyleString.string(custom)}"`);
});

/**
 * template[string] - which page class to map the value from; ie: 'content'
 * theme[string] - which theme to map the value from; ie: `light`
 * anchor[string] - the mapped value for data-anchor; ie: 'Welcome-to-Proxy'
 */
Handlebars.registerHelper('parentClass', (template, theme, anchor, className, pageName) => {
    return !template ? '' : new Handlebars.SafeString(`class="section ${template}-page ${template}-page--${theme || 'light'} section--${theme || 'light'} ${className || ''}" id="section-${pageName.split(' ').join('-')}" data-anchor="${anchor}"`);
});

/**
 * block[string] - which page class to map the value from; ie: 'content'
 * sectionTheme[string] - which theme to map the value from; ie: `light`
 * className[string] - the mapped value for a custom class name'
 */
Handlebars.registerHelper('sectionClass', (block, sectionTheme, className) => {
    const custom = className ? `${block}_section--${className}` : '';
    const theme = `${block}_section--${sectionTheme || 'light'}`;

    return new Handlebars.SafeString(`${block}_section ${theme} ${custom}`);
});

Handlebars.registerHelper('boxStyle', (borderRadius, border, padding, boxColor, cssObject) => {
    const htmlString = StyleString.string(cssObject || {}) || '';
    const radius = !borderRadius ? '' : `border-radius:${borderRadius};`;
    const boxBorder = !border ? '' : `border:${border};`;
    const boxPadding = !padding ? '' : `padding:${padding};`;
    const bgColor = !boxColor ? '' : `background-color:${boxColor};`;
    const style = `${bgColor}${boxPadding}${boxBorder}${radius}${htmlString}`;

    if (!style) {
        return '';
    }
    return new Handlebars.SafeString(`style="${style}"`);
});


Handlebars.registerHelper('cssToString', (cssObject) => {
    // TODO - Not the safest option, maybe need to take into account absolute paths
    const htmlString = StyleString.string(cssObject || {});
    return new Handlebars.SafeString(htmlString);
});