var Handlebars = require("/home/rof/src/github.com/q4mobile/interactive-proxy/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "    <div role=\"contentinfo\" id=\"cookie-banner\" class=\"popup\" "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.cookieBanner : depth0)) != null ? stack1.css : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n        <a role=\"button\" tabindex=\"0\" class=\"popup_close q4proxy-close-4pt\"><span class=\"sr-only\">Closes the cookie popup</span></a>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.cookieBanner : depth0)) != null ? stack1.heading : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.cookieBanner : depth0)) != null ? stack1.message : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.cookieBanner : depth0)) != null ? stack1.button : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression((helpers.customStyle || (depth0 && depth0.customStyle) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.cookieBanner : depth0)) != null ? stack1.css : stack1),{"name":"customStyle","hash":{},"data":data}));
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.cookieBanner : depth0)) != null ? stack1.heading : stack1)) != null ? stack1.text : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <h2 class=\"popup_heading\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.cookieBanner : depth0)) != null ? stack1.heading : stack1)) != null ? stack1.css : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.cookieBanner : depth0)) != null ? stack1.heading : stack1)) != null ? stack1.text : stack1), depth0)) != null ? stack1 : "")
    + "</h2>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression((helpers.customStyle || (depth0 && depth0.customStyle) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.cookieBanner : depth0)) != null ? stack1.heading : stack1)) != null ? stack1.css : stack1),{"name":"customStyle","hash":{},"data":data}));
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.cookieBanner : depth0)) != null ? stack1.message : stack1)) != null ? stack1.text : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <div class=\"popup_message\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.cookieBanner : depth0)) != null ? stack1.message : stack1)) != null ? stack1.css : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.cookieBanner : depth0)) != null ? stack1.message : stack1)) != null ? stack1.text : stack1), depth0)) != null ? stack1 : "")
    + "</div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression((helpers.customStyle || (depth0 && depth0.customStyle) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.cookieBanner : depth0)) != null ? stack1.message : stack1)) != null ? stack1.css : stack1),{"name":"customStyle","hash":{},"data":data}));
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <a role=\"button\" tabindex=\"0\" class=\"btn popup_link popup_link--dismiss\" "
    + container.escapeExpression((helpers.style || (depth0 && depth0.style) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"border-color background-color color","btnBorderColor btnBgColor btnTextColor",((stack1 = (data && data.root)) && stack1.theme),((stack1 = (data && data.root)) && stack1.defaultCss),null,((stack1 = ((stack1 = (depth0 != null ? depth0.cookieBanner : depth0)) != null ? stack1.button : stack1)) != null ? stack1.css : stack1),{"name":"style","hash":{},"data":data}))
    + ">"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.cookieBanner : depth0)) != null ? stack1.button : stack1)) != null ? stack1.text : stack1), depth0)) != null ? stack1 : "")
    + "</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.cookieBanner : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});