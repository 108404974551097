require('jquery-countdown');
require('jquery.scrollbar');
require('@fancyapps/fancybox');
require('./swipe');
require('fullpage.js');

const Config = require('../config');

module.exports = {
    clickHandlers: (proxyData) => {
        $('.event_info-btn').on('click keypress', function (e) {
            const theme = $(this).closest('.section').hasClass('section--dark') ? 'dark' : 'light';
            const proxyTheme = !proxyData || !proxyData.defaultCss || !proxyData.defaultCss[theme] ? '' : proxyData.defaultCss[theme];
            const bgColorActive = !proxyTheme || !proxyTheme.eventBgColor ? 'inherit' : proxyTheme.eventBgColor;

            if (e.which === 1 || e.keyCode === 13) {
                const $container = $(this).closest('.event').toggleClass('event--details-open');

                $(this).toggleClass('event_info-btn--active');

                $(this).hasClass('event_info-btn--active') ?
                    $container.not('.event--compressed').css("background-color", bgColorActive) :
                    $container.not('.event--compressed').css("background-color", "transparent");
            }
        });

        $('.event_details-close-btn').on('click keypress', function (e) {
            if (e.which === 1 || e.keyCode === 13) {
                $(this).closest('.event').toggleClass('event--details-open');
            }
        });

        $(document).mouseup(function (e) {
            const $eventOverview = $('.event');
            if ($eventOverview.length && !$eventOverview.is(e.target) && $eventOverview.has(e.target).length === 0 && $eventOverview.find('.event_info-btn').attr('class').indexOf('active') > -1) {
                $eventOverview.find('.event_info-btn').trigger({
                    type: 'click',
                    which: 1
                });
            }
        });

        $('.sf-menu').on('click keypress', 'li > ul > li', function (e) {

            if (e.which === 1 || e.keyCode === 13) {
                $('.directors-page_members').addClass('isFiltered');
                $(this).parent().hide();
            }
        });

        $('.ddd-container').on('click keypress', '.ddd-keep', function (e) {
            e.preventDefault();
            const ddd = $(this).closest('.ddd-container').data('dotdotdot');
            const $container = $(this).closest('.ddd-container');
            const allowFPscroll = (allow) => {
                if (typeof fullpage_api !== 'object') return;

                fullpage_api.setAllowScrolling(allow);
                fullpage_api.setMouseWheelScrolling(allow);
            };

            if (e.which === 1 || e.keyCode === 13) {
                if ($container.hasClass('ddd-truncated')) {
                    ddd.restore();
                    $container.addClass('full-content');

                    allowFPscroll(false);

                    if (!proxyData.config.disableFullpageScrolling) {
                        $container.unbind('mouseover').on('mouseover', function (mouseoverEvent) {
                            if ($(mouseoverEvent.target).closest($container)) {
                                allowFPscroll(false);
                            }
                        });
                        $container.unbind('mouseout').on('mouseout', function () {
                            allowFPscroll(true);
                        });
                    }
                } else {
                    $container.removeClass('full-content').unbind('mouseover mouseout');
                    allowFPscroll(true);
                    ddd.truncate();
                    ddd.watch();
                }
            }
        });

        const $highlightsLink = $('.highlights-page_learn-more-btn');
        $highlightsLink.on('click keypress', function (e) {
            e.preventDefault();
            if (e.which === 1 || e.keyCode === 13) {
                $(this).parent().find('.learn-more_links a').eq(0).trigger({
                    type: 'click',
                    which: 1
                });
                return false;
            }
        });

        const $language = $('.language');
        $language.on('click keypress', '.language_current', function (e) {
            if (e.which === 1 || e.keyCode === 13) {
                const $container = $(this).closest('.language');
                $container.hasClass('language--toggled') ? $container.removeClass('language--toggled') : $container.addClass('language--toggled');
            }
        });
    },
    navHandlers: (proxyData) => {
        const $navigation = $('.navigation, .header');
        const $body = $('body');
        $navigation.on('click keypress', '.navigation_button', function () {
            stopBodyScroll(true);

            $navigation.find('.navigation_container').addClass('open').parent().addClass('active').find('.navigation_overlay').addClass('active');
            $('html').addClass('navigation-open');

            typeof fullpage_api === 'object' && fullpage_api.setAllowScrolling(false);
        });

        if (proxyData.config.disableFullpageScrolling) {
            $('#fp-nav').on('click', 'a', function (e) {
                e.preventDefault();

                if ((($(this).data('href') && $(this).data('href').indexOf('#') === 0)) || ($(this).attr('href') && $(this).attr('href').indexOf('#') === 0)) {
                    const href = !$(this).data('href') ? $(this).attr('href') : $(this).data('href');
                    $('html, body').animate({
                        scrollTop: $('[data-anchor="' + href.split('#').pop() + '"]').position().top
                    }, 'slow');
                    if (!$(this).data('href')) {
                        window.location = $(this).data('href');
                    }
                }
                return false;
            });
        }

        $navigation.on('click keypress', '.navigation_overlay, a', function () {
            if (typeof fullpage_api === 'undefined' || proxyData.config.disableFullpageScrolling) {
                if ((($(this).data('href') && $(this).data('href').indexOf('#') === 0)) || ($(this).attr('href') && $(this).attr('href').indexOf('#') === 0)) {
                    const href = !$(this).data('href') ? $(this).attr('href') : $(this).data('href');
                    $('html, body').animate({
                        scrollTop: $('[data-anchor="' + href.split('#').pop() + '"]').position().top
                    }, 'slow');
                    if (!$(this).data('href')) {
                        window.location = $(this).data('href');
                    }
                }
            } else {
                if ($(this).data('href')) {
                    window.location = $(this).data('href');
                }
                typeof fullpage_api === 'object' && fullpage_api.setAllowScrolling(true);
            }
            closeNav();
        });

        $body.swipe(function (direction, offset, event) {
            if ($navigation.hasClass('active') && direction.x === "right" && offset.x > 80) {
                closeNav();
            }
        });

        function closeNav() {
            $navigation.find('.navigation_container').removeClass('open').parent().removeClass('active').find('.navigation_overlay').removeClass('active');
            const $eventBtn = $navigation.find('.event_info-btn');

            if ($eventBtn.hasClass('event_info-btn--active')) {
                $eventBtn.trigger({
                    type: 'click',
                    which: 1
                });
            }

            $('.event--compressed').removeClass('event--details-open');
            $('html').removeClass('navigation-open');

            stopBodyScroll(false);
        }
    },
    onTabClick: (proxyData) => {
        $('.tabs--default').each(function () {
            const theme = $(this).closest('.section').hasClass('section--dark') ? 'dark' : 'light';
            const proxyTheme = !proxyData || !proxyData.defaultCss || !proxyData.defaultCss[theme] ? '' : proxyData.defaultCss[theme];
            const borderColorActive = proxyTheme.tabBorderColorActive || 'inherit';
            const bgColorActive = proxyTheme.tabBgColorActive || 'inherit';
            const borderColor = proxyTheme.tabBorderColor || 'inherit';
            const bgColor = proxyTheme.tabBgColor || 'inherit';
            const textColor = proxyTheme.tabTextColor || 'inherit';
            const textColorActive = proxyTheme.tabTextColorActive || 'inherit';

            $(this).on('click keypress', 'li', function () {
                const index = $(this).index();
                const $parent = $(this).closest('.section');
                const $container = $(this).closest('[class*="_container"]');

                $(this).addClass('tabs_item--active').css({
                    "border-color": borderColorActive,
                    "background-color": bgColorActive,
                    "color": textColorActive
                }).siblings().css({
                    "border-color": borderColor,
                    "background-color": bgColor,
                    "color": textColor
                }).removeClass('tabs_item--active');

                $(this).data('title') && $parent.find('.section_title').text($(this).data('title'));
                $parent.find('.tab-content').removeClass('tab-content--active').eq(index).addClass('tab-content--active');

                if ($container.outerHeight() > window.innerHeight) {
                    typeof fullpage_api === 'object' && fullpage_api.reBuild();
                }

                $('body, html').animate({scrollTop: $container.offset().top, scrollLeft: 0}, 0);

            }).find('li').first().addClass('tabs_item--active').css({
                "border-color": borderColorActive,
                "background-color": bgColorActive,
                "color": textColorActive
            });
        });
    },
    onTableTabClick: (proxyData) => {
        $('.tabs--table').each(function () {
            const theme = $(this).closest('.section').hasClass('section--dark') ? 'dark' : 'light';
            const proxyTheme = !proxyData || !proxyData.defaultCss || !proxyData.defaultCss[theme] ? '' : proxyData.defaultCss[theme];
            const borderColorActive = proxyTheme.tabBorderColorActive || 'inherit';
            const bgColorActive = proxyTheme.tabBgColorActive || 'inherit';
            const borderColor = proxyTheme.tabBorderColor || 'inherit';
            const bgColor = proxyTheme.tabBgColor || 'inherit';

            $(this).on('click keypress', 'li', function (e) {
                const index = $(this).index();
                const $parent = $(this).closest('.section');
                const $container = $(this).closest('[class*="_container"]');

                if (e.which === 1 || e.keyCode === 13) {
                    $(this).addClass('tabs_item--active').css({
                        "border-color": borderColorActive,
                        "background-color": bgColorActive
                    }).siblings().css({
                        "border-color": borderColor,
                        "background-color": bgColor
                    }).removeClass('tabs_item--active');

                    $(this).data('title') && $parent.find('.section_title').text($(this).data('title'));

                    $parent.find('.tab-content').removeClass('tab-content--active').eq(index).addClass('tab-content--active');

                    if ($container.outerHeight() > window.innerHeight) {
                        typeof fullpage_api === 'object' && fullpage_api.reBuild();
                    }

                    $('body, html').animate({scrollTop: $container.offset().top, scrollLeft: 0}, 0);
                }
            }).find('li').first().addClass('tabs_item--active').css({
                "border-color": borderColorActive,
                "background-color": bgColorActive
            });
        });
    },
    onNomineesMenuClick: (proxyData) => {
        // Not reusable for multiple sections
        // Not flexible for a template change
        const $items = $('.directors-page_box');
        const theme = $(this).closest('.section').hasClass('section--dark') ? 'dark' : 'light';
        const proxyTheme = !proxyData || !proxyData.defaultCss || !proxyData.defaultCss[theme] ? '' : proxyData.defaultCss[theme];
        const bgColor = proxyTheme.menuActiveBgColor || '';
        const textColor = proxyTheme.menuActiveTextColor || '';

        $('.directors-page_menu-item--all').on('click keypress', function (e) {

            if (e.which === 1 || e.keyCode === 13) {
                $(this).css({
                    "background-color": bgColor,
                    "border-color": bgColor,
                    "color": textColor
                }).siblings().css({
                    "background-color": '',
                    "border-color": '',
                    "color": ''
                });
                $(this).addClass('directors-page_menu-item--active').siblings().removeClass('directors-page_menu-item--active');
                $('.directors-page_selected-filter').removeClass('filtered');
                $items.removeClass('directors-page_box--disabled');
            }
        }).css({
            "background-color": bgColor,
            "border-color": bgColor,
            "color": textColor
        });

        $('.directors-page_menu').on('click keypress', 'li > ul > li', function (e) {
            const type = $(this).parent().data('type');
            const category = $(this).text();

            if (e.which === 1 || e.keyCode === 13) {
                $(this).closest('.directors-page_menu-item').addClass('directors-page_menu-item--active').css({
                    "background-color": bgColor,
                    "border-color": bgColor,
                    "color": textColor
                }).siblings().css({
                    "background-color": '',
                    "border-color": '',
                    "color": ''
                }).removeClass('directors-page_menu-item--active');
                $items.addClass('directors-page_box--disabled');

                $('.directors-page_selected-filter').addClass('filtered').find('span').text(`${category}`);
                switch (type) {
                    case "Leadership":
                        $items.filter(`[data-leadership*="${category}"]`).removeClass('directors-page_box--disabled');
                        break;
                    case "Committees":
                        $items.filter(`[data-committee*="${category}"]`).removeClass('directors-page_box--disabled');
                        break;
                    case "Tenure":
                        $items.filter(`[data-tenure*="${category}"]`).removeClass('directors-page_box--disabled');
                        break;
                    case "Age":
                        const min = category.split(/[-+]/)[0];
                        const max = category.split(/[-+]/)[1] === "" ? "200" : category.split(/[-+]/)[1];

                        $('[data-age]').each(function () {
                            if ($(this).data('age') >= min && $(this).data('age') <= max) {
                                $(this).removeClass('directors-page_box--disabled');
                            }
                        });
                        break;
                    case "Independence":
                        category === "Yes" ?
                            $items.filter(`[data-independent="true"]`).removeClass('directors-page_box--disabled') :
                            $items.filter(`[data-independent="false"]`).removeClass('directors-page_box--disabled');
                        break;
                    default:
                        $items.removeClass('directors-page_box--disabled');
                        break;
                }
            }
        });
    },
    onBtnHover: (proxyData) => {
        $('.btn').each(function () {
            const theme = $(this).closest('.section').hasClass('section--dark') ? 'dark' : 'light';
            const proxyTheme = !proxyData || !proxyData.defaultCss || !proxyData.defaultCss[theme] ? '' : proxyData.defaultCss[theme];
            const borderColorActive = proxyTheme.btnBorderColorActive || '';
            const bgColorActive = proxyTheme.btnBgColorActive || '';
            const borderColor = proxyTheme.btnBorderColor || '';
            const bgColor = proxyTheme.btnBgColor || '';
            const color = proxyTheme.btnTextColor || '';
            const colorActive = proxyTheme.btnTextColorActive || '';

            $(this).on('mouseover', function () {
                $(this).css({
                    "background-color": bgColorActive,
                    "border-color": borderColorActive,
                    "color": colorActive
                })
            });

            $(this).on('mouseout', function () {
                $(this).css({
                    "background-color": bgColor,
                    "border-color": borderColor,
                    "color": color
                })
            });
        });
    },
    onMenuHover: (proxyData) => {
        $('.directors-page_menu-item > ul li').each(function () {
            const theme = $(this).closest('.section').hasClass('section--dark') ? 'dark' : 'light';
            const proxyTheme = !proxyData || !proxyData.defaultCss || !proxyData.defaultCss[theme] ? '' : proxyData.defaultCss[theme];
            const bgColor = proxyTheme.menuListHoverBgColor || 'inherit';
            const textColor = proxyTheme.menuListHoverTextColor || '';

            $(this).on('mouseover', function () {
                $(this).css({
                    "background-color": bgColor,
                    "color": textColor
                })
            });

            $(this).on('mouseout', function () {
                $(this).css({
                    "background-color": '',
                    "color": ''
                })
            });
        });

        $('.directors-page_menu-item').hover(function () {
            const theme = $(this).closest('.section').hasClass('section--dark') ? 'dark' : 'light';
            const proxyTheme = !proxyData || !proxyData.defaultCss || !proxyData.defaultCss[theme] ? '' : proxyData.defaultCss[theme];
            const bgColor = proxyTheme.menuActiveBgColor || '';
            const textColor = proxyTheme.menuActiveTextColor || '';
            $(this).css({
                "background-color": bgColor,
                "color": textColor,
                "border-color": bgColor
            })
        }, function () {
            if (!$(this).hasClass('directors-page_menu-item--active')) {
                $(this).css({
                    "background-color": '',
                    "color": '',
                    "border-color": ''
                })
            }
        });
    },
    onNomineeKeyEnter: () => {
        $('.directors-page_box').each(function (i, item) {
            $(item).on('keypress', function (e) {
                if (e.keyCode === 13) {
                    $(item).trigger({
                        type: 'click',
                        which: 1
                    });
                }
            })
        })
    },
    onScrollHandlers: () => {
        function collapseHeader() {
            if (window.innerWidth < 960) {
                $(window).on('scroll', function () {
                    const $header = $('.header') || null;
                    if ($header && $(this).scrollTop() > $header.height()) {
                        $header.addClass('header--collapsed');
                    } else {
                        $header.removeClass('header--collapsed');
                    }
                })
            }
        }

        $(window).resize(function () {
            collapseHeader();
        });
        collapseHeader();
    },
    fpStopScroll: (activeSection) => {
        const section = activeSection[0];
        const container = section.children[0];
        const allowFPscroll = (allow) => {
            if (typeof fullpage_api !== 'object') return;

            fullpage_api.setAllowScrolling(allow);
            fullpage_api.setMouseWheelScrolling(allow);
        };
        let wheeling;
        let fpAllowScrolling = false;
        let autoTop;

        if (section.classList.contains('ip-scrollable')) {

            $(section).on('wheel', function (e) {
                if (!$(e.target).closest('.ddd-container').hasClass('full-content') || !$(e.target).closest('.fancybox-container')) {
                    const upScroll = e.originalEvent.wheelDeltaY > 0 || e.originalEvent.deltaY < 0;
                    const scrollTop = $(container).scrollTop();
                    if (fpAllowScrolling) {
                        allowFPscroll(true);
                        clearTimeout(autoTop);
                        autoTop = setTimeout(function () {
                            $(container).scrollTop(0);
                        }, 200);
                    } else {
                        allowFPscroll(false);
                    }

                    if (!wheeling) {
                        if (upScroll) {
                            if (scrollTop <= 0) {
                                fpAllowScrolling = true;
                            }
                        } else {
                            if (Math.ceil(scrollTop + $(container).innerHeight()) >= container.scrollHeight) {
                                fpAllowScrolling = true;
                            }
                        }
                    } else {
                        fpAllowScrolling = false;
                    }

                    clearTimeout(wheeling);
                    wheeling = setTimeout(function () {
                        wheeling = undefined;
                        fpAllowScrolling = false;
                    }, 400);
                }
            });
        } else {
            allowFPscroll(true);
        }
    },
    popupHandlers: (proxyData) => {
        const retention = !proxyData.cookieBanner || !proxyData.cookieBanner.retention ? Config.cookieRetention : proxyData.cookieBanner.retention;
        const expiryDate = new Date(new Date().setDate(new Date().getDate() + retention)).toGMTString();
        const $banner = $('#cookie-banner');

        $banner.on('click', '.popup_link--dismiss', function () {
            $banner.addClass('dismissed');
            setBannerCookie('accepted', expiryDate);
        });
        $banner.on('click', '.popup_close', function () {
            $banner.addClass('dismissed');
            setBannerCookie('dismissed', expiryDate);
        });
    }
}
;

function setBannerCookie(value, expiryDate) {
    document.cookie = `cookie_status=${value}; expires=${expiryDate}; path=${window.location.pathname}`;
}

function stopBodyScroll(bool) {
    if (bool) document.addEventListener("touchmove", stopScroll, {passive: false});
    else document.removeEventListener("touchmove", stopScroll);
}

const stopScroll = function (e) {
    e.preventDefault();
};