var Handlebars = require("/home/rof/src/github.com/q4mobile/interactive-proxy/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                        <li "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.css : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + container.escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " style=\""
    + container.escapeExpression((helpers.cssToString || (depth0 && depth0.cssToString) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.css : depth0),{"name":"cssToString","hash":{},"data":data}))
    + "\" ";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                <div class=\"multilist-page_item-container\"\n                     "
    + ((stack1 = (helpers.ifCondition || (depth0 && depth0.ifCondition) || helpers.helperMissing).call(alias1,"vertical",(depths[1] != null ? depths[1].orientation : depths[1]),{"name":"ifCondition","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.heading : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.blurb : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.lists : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.details : depth0)) != null ? stack1.body : stack1),{"name":"if","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "style=\"width: calc(100% / "
    + alias2(alias1(((stack1 = (depths[1] != null ? depths[1].items : depths[1])) != null ? stack1.length : stack1), depth0))
    + " - 30px / "
    + alias2(alias1(((stack1 = (depths[1] != null ? depths[1].items : depths[1])) != null ? stack1.length : stack1), depth0))
    + ");\"";
},"7":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<h3 class=\"multilist-page_heading\">"
    + container.escapeExpression(((helper = (helper = helpers.heading || (depth0 != null ? depth0.heading : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"heading","hash":{},"data":data}) : helper)))
    + "</h3>";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "<p class=\"multilist-page_blurb\">"
    + ((stack1 = ((helper = (helper = helpers.blurb || (depth0 != null ? depth0.blurb : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"blurb","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>";
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.details : depth0)) != null ? stack1.body : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.program(15, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing, alias5="function";

  return "                            <div data-fancybox data-src=\"#proposal-"
    + alias2(alias1((container.data(data, 1) && container.data(data, 1).index), depth0))
    + "-"
    + alias2(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"index","hash":{},"data":data}) : helper)))
    + "\" class=\"multilist-page_item\" "
    + alias2((helpers.style || (depth0 && depth0.style) || alias4).call(alias3,"color","textColor",((stack1 = (data && data.root)) && stack1.theme),((stack1 = (data && data.root)) && stack1.defaultCss),null,(depth0 != null ? depth0.css : depth0),{"name":"style","hash":{},"data":data}))
    + ">\n                                <div class=\"multilist-page_item-text\">\n                                    <p>"
    + alias2(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"text","hash":{},"data":data}) : helper)))
    + "</p>\n                                </div>\n                                <div class=\"multilist-page_type\" "
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.choice : depth0)) != null ? stack1.css : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                                    <span>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.choice : depth0)) != null ? stack1.text : stack1), depth0))
    + "</span>\n                                </div>\n                            </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " style=\""
    + container.escapeExpression((helpers.cssToString || (depth0 && depth0.cssToString) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.choice : depth0)) != null ? stack1.css : stack1),{"name":"cssToString","hash":{},"data":data}))
    + "\" ";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                            <div class=\"multilist-page_item\" "
    + alias3((helpers.style || (depth0 && depth0.style) || alias2).call(alias1,"color","textColor",((stack1 = (data && data.root)) && stack1.theme),((stack1 = (data && data.root)) && stack1.defaultCss),null,(depth0 != null ? depth0.css : depth0),{"name":"style","hash":{},"data":data}))
    + ">\n                                <div class=\"multilist-page_item-text\">\n                                    <p>"
    + alias3(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "</p>\n                                </div>\n                                <div class=\"multilist-page_type\" "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.choice : depth0)) != null ? stack1.css : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                                    <span>"
    + alias3(container.lambda(((stack1 = (depth0 != null ? depth0.choice : depth0)) != null ? stack1.text : stack1), depth0))
    + "</span>\n                                </div>\n                                "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.details : depth0)) != null ? stack1.link : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                            </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "<a href=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.details : depth0)) != null ? stack1.link : stack1), depth0))
    + "\" class=\"multilist-page_item-link\" target=\"_blank\"><span class=\"sr-only\">Link to "
    + alias1(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"text","hash":{},"data":data}) : helper)))
    + "</span></a>";
},"18":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                        <div class=\"multilist-page_list-details\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.lists : depth0),{"name":"each","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                        </div>\n";
},"19":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing;

  return "                                <div id=\"proposal-"
    + alias2(alias1((container.data(data, 1) && container.data(data, 1).index), depth0))
    + "-"
    + alias2(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias4),(typeof helper === "function" ? helper.call(alias3,{"name":"index","hash":{},"data":data}) : helper)))
    + "\" class=\"details_container\">\n                                    <div class=\"details_overlay\"></div>\n                                    <div class=\"scrollbar-wrapper scrollbar-macosx\">\n                                        <div class=\"details_content\">\n                                            <h2 class=\"details_title\" "
    + alias2((helpers.style || (depth0 && depth0.style) || alias4).call(alias3,"color","titleColor",((stack1 = (data && data.root)) && stack1.theme),((stack1 = (data && data.root)) && stack1.defaultCss),null,null,{"name":"style","hash":{},"data":data}))
    + ">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.details : depth0)) != null ? stack1.title : stack1), depth0))
    + "</h2>\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.details : depth0)) != null ? stack1.body : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.details : depth0)) != null ? stack1.link : stack1),{"name":"if","hash":{},"fn":container.program(22, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                                        </div>\n                                    </div>\n                                </div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                                <div class=\"details_body\">\n                                                    "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? depth0.details : depth0)) != null ? stack1.body : stack1), depth0)) != null ? stack1 : "")
    + "\n                                                </div>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression;

  return "                                                <div class=\"details_link\">\n                                                    <a href=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.details : depth0)) != null ? stack1.link : stack1), depth0))
    + "\" class=\"details_read-more-btn btn\" target=\"_blank\" "
    + alias1((helpers.style || (depth0 && depth0.style) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"border-color background-color color","btnBorderColor btnBgColor btnTextColor",((stack1 = (data && data.root)) && stack1.theme),((stack1 = (data && data.root)) && stack1.defaultCss),null,null,{"name":"style","hash":{},"data":data}))
    + "\n                                                    \">Read\n                                                    More</a>\n                                                </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div "
    + alias3((helpers.parentClass || (depth0 && depth0.parentClass) || alias2).call(alias1,(depth0 != null ? depth0.template : depth0),(depth0 != null ? depth0.theme : depth0),(depth0 != null ? depth0.anchor : depth0),(depth0 != null ? depth0.className : depth0),(depth0 != null ? depth0.pageName : depth0),{"name":"parentClass","hash":{},"data":data}))
    + " "
    + alias3((helpers.style || (depth0 && depth0.style) || alias2).call(alias1,"color background-color","textColor templateBgColor",(depth0 != null ? depth0.theme : depth0),(depth0 != null ? depth0.defaultCss : depth0),null,(depth0 != null ? depth0.css : depth0),{"name":"style","hash":{},"data":data}))
    + ">\n    <div class=\"multilist-page_container\">\n        <div class=\"multilist-page_header\">\n            <h2 class=\"multilist-page_title section_title\" "
    + alias3((helpers.style || (depth0 && depth0.style) || alias2).call(alias1,"color","titleColor",(depth0 != null ? depth0.theme : depth0),(depth0 != null ? depth0.defaultCss : depth0),null,null,{"name":"style","hash":{},"data":data}))
    + ">"
    + alias3(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h2>\n            <div class=\"multilist-page_legend\">\n                <h3>"
    + alias3(container.lambda(((stack1 = (depth0 != null ? depth0.legend : depth0)) != null ? stack1.title : stack1), depth0))
    + "</h3>\n                <ul class=\"multilist-page_legend-list\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.legend : depth0)) != null ? stack1.items : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </ul>\n            </div>\n        </div>\n        <div class=\"multilist-page_body "
    + alias3((helpers.alignmentOrientation || (depth0 && depth0.alignmentOrientation) || alias2).call(alias1,(depth0 != null ? depth0.orientation : depth0),(depth0 != null ? depth0.alignment : depth0),{"name":"alignmentOrientation","hash":{},"data":data}))
    + "\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>";
},"useData":true,"useDepths":true});