var Handlebars = require("/home/rof/src/github.com/q4mobile/interactive-proxy/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "            <div class=\""
    + alias3((helpers.sectionClass || (depth0 && depth0.sectionClass) || alias2).call(alias1,"content-page",(depth0 != null ? depth0.theme : depth0),(depth0 != null ? depth0.className : depth0),{"name":"sectionClass","hash":{},"data":data}))
    + " "
    + alias3((helpers.alignmentOrientation || (depth0 && depth0.alignmentOrientation) || alias2).call(alias1,(depth0 != null ? depth0.orientation : depth0),(depth0 != null ? depth0.alignment : depth0),{"name":"alignmentOrientation","hash":{},"data":data}))
    + "\"\n                "
    + alias3((helpers.style || (depth0 && depth0.style) || alias2).call(alias1,"color background-color","textColor templateBgColor",(depth0 != null ? depth0.theme : depth0),((stack1 = (data && data.root)) && stack1.defaultCss),(depth0 != null ? depth0.width : depth0),(depth0 != null ? depth0.css : depth0),{"name":"style","hash":{},"data":data}))
    + ">\n                <h2 class=\"content-page_title "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.className : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " section_title\"\n                    "
    + alias3((helpers.style || (depth0 && depth0.style) || alias2).call(alias1,"color","titleColor",(depth0 != null ? depth0.theme : depth0),((stack1 = (data && data.root)) && stack1.defaultCss),null,(depth0 != null ? depth0.css : depth0),{"name":"style","hash":{},"data":data}))
    + ">\n                    "
    + ((stack1 = ((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n                </h2>\n                <div class=\"content-page_section-body "
    + alias3((helpers.alignmentOrientation || (depth0 && depth0.alignmentOrientation) || alias2).call(alias1,(depth0 != null ? depth0.orientation : depth0),(depth0 != null ? depth0.alignmentBody : depth0),{"name":"alignmentOrientation","hash":{},"data":data}))
    + "\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\n            </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper;

  return "content-page_title--"
    + container.escapeExpression(((helper = (helper = helpers.className || (depth0 != null ? depth0.className : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"className","hash":{},"data":data}) : helper)));
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                        "
    + container.escapeExpression((helpers.htmlParser || (depth0 && depth0.htmlParser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,(depths[1] != null ? depths[1].theme : depths[1]),((stack1 = (data && data.root)) && stack1.defaultCss),{"name":"htmlParser","hash":{},"data":data}))
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div "
    + alias3((helpers.parentClass || (depth0 && depth0.parentClass) || alias2).call(alias1,(depth0 != null ? depth0.template : depth0),(depth0 != null ? depth0.theme : depth0),(depth0 != null ? depth0.anchor : depth0),(depth0 != null ? depth0.className : depth0),(depth0 != null ? depth0.pageName : depth0),{"name":"parentClass","hash":{},"data":data}))
    + " "
    + alias3((helpers.customStyle || (depth0 && depth0.customStyle) || alias2).call(alias1,(depth0 != null ? depth0.css : depth0),{"name":"customStyle","hash":{},"data":data}))
    + ">\n    <div class=\"content-page_container "
    + alias3((helpers.alignmentOrientation || (depth0 && depth0.alignmentOrientation) || alias2).call(alias1,(depth0 != null ? depth0.orientation : depth0),(depth0 != null ? depth0.alignment : depth0),{"name":"alignmentOrientation","hash":{},"data":data}))
    + "\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.sections : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n</div>";
},"useData":true,"useDepths":true});