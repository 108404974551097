var Handlebars = require("/home/rof/src/github.com/q4mobile/interactive-proxy/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return " "
    + container.escapeExpression(((helper = (helper = helpers.className || (depth0 != null ? depth0.className : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"className","hash":{},"data":data}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        "
    + container.escapeExpression((helpers.htmlParser || (depth0 && depth0.htmlParser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,(depth0 != null ? depth0.theme : depth0),((stack1 = (data && data.root)) && stack1.defaultCss),{"name":"htmlParser","hash":{},"data":data}))
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"content-page_box"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.className : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" "
    + container.escapeExpression((helpers.boxStyle || (depth0 && depth0.boxStyle) || helpers.helperMissing).call(alias1,(depth0 != null ? depth0.borderRadius : depth0),(depth0 != null ? depth0.boxBorder : depth0),(depth0 != null ? depth0.boxPadding : depth0),(depth0 != null ? depth0.boxColor : depth0),(depth0 != null ? depth0.css : depth0),{"name":"boxStyle","hash":{},"data":data}))
    + ">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true});