var Handlebars = require("/home/rof/src/github.com/q4mobile/interactive-proxy/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                <div class=\"highlights-page_item\">\n                    <div class=\"highlights-page_icon "
    + alias4(((helper = (helper = helpers.icon || (depth0 != null ? depth0.icon : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"icon","hash":{},"data":data}) : helper)))
    + "\"></div>\n                    <div class=\"highlights-page_item-info\">\n                        <div class=\"highlights-page_item-numbers\"><span class=\"counter\">"
    + ((stack1 = ((helper = (helper = helpers.metric || (depth0 != null ? depth0.metric : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"metric","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span></div>\n                        <div class=\"highlights-page_item-heading\">"
    + alias4(((helper = (helper = helpers.header || (depth0 != null ? depth0.header : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"header","hash":{},"data":data}) : helper)))
    + "</div>\n                        <div class=\"highlights-page_item-brief\">"
    + alias4(((helper = (helper = helpers.blurb || (depth0 != null ? depth0.blurb : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"blurb","hash":{},"data":data}) : helper)))
    + "</div>\n                    </div>\n                </div>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "            <a class=\"highlights-page_learn-more-btn btn\" href=\"#\" "
    + alias3((helpers.style || (depth0 && depth0.style) || alias2).call(alias1,"border-color background-color color","btnBorderColor btnBgColor btnTextColor",((stack1 = (data && data.root)) && stack1.theme),((stack1 = (data && data.root)) && stack1.defaultCss),null,null,{"name":"style","hash":{},"data":data}))
    + ">"
    + alias3(((helper = (helper = helpers.buttonText || (depth0 != null ? depth0.buttonText : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"buttonText","hash":{},"data":data}) : helper)))
    + "</a>\n            <div id=\"details\" class=\"learn-more\">\n                <div class=\"learn-more_links\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.details : depth0)) != null ? stack1.sections : stack1),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.details : depth0)) != null ? stack1.sections : stack1),{"name":"each","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                        <a data-fancybox=\"highlights\" data-base-class=\"fancybox-container--highlights\" href=\"#details-"
    + alias4(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "\">details-"
    + alias4(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "</a>\n";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                    <div id=\"details-"
    + alias4(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "\" class=\"fancybox_learn-more\">\n                        <div class=\"learn-more_content\">\n                            <div class=\"learn-more_overlay\"></div>\n                            <div class=\"scrollbar-wrapper scrollbar-macosx\">\n                                <div class=\""
    + alias4((helpers.sectionClass || (depth0 && depth0.sectionClass) || alias2).call(alias1,"highlights",(depth0 != null ? depth0.theme : depth0),(depth0 != null ? depth0.className : depth0),{"name":"sectionClass","hash":{},"data":data}))
    + "\"\n                                    "
    + alias4((helpers.style || (depth0 && depth0.style) || alias2).call(alias1,"color","textColor",(depth0 != null ? depth0.theme : depth0),((stack1 = (data && data.root)) && stack1.defaultCss),(depth0 != null ? depth0.width : depth0),(depth0 != null ? depth0.css : depth0),{"name":"style","hash":{},"data":data}))
    + ">\n                                    <h2 class=\"highlights-detail_title "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.className : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " section_title\"\n                                        "
    + alias4((helpers.style || (depth0 && depth0.style) || alias2).call(alias1,"color","titleColor",(depth0 != null ? depth0.theme : depth0),((stack1 = (data && data.root)) && stack1.defaultCss),null,(depth0 != null ? depth0.css : depth0),{"name":"style","hash":{},"data":data}))
    + ">\n                                        "
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "\n                                    </h2>\n                                    <div class=\"highlights-detail_section-body\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                                    </div>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper;

  return "highlights-detail_title--"
    + container.escapeExpression(((helper = (helper = helpers.className || (depth0 != null ? depth0.className : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"className","hash":{},"data":data}) : helper)));
},"9":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                                            "
    + container.escapeExpression((helpers.htmlParser || (depth0 && depth0.htmlParser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,(depths[1] != null ? depths[1].theme : depths[1]),((stack1 = (data && data.root)) && stack1.defaultCss),{"name":"htmlParser","hash":{},"data":data}))
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div "
    + alias3((helpers.parentClass || (depth0 && depth0.parentClass) || alias2).call(alias1,(depth0 != null ? depth0.template : depth0),(depth0 != null ? depth0.theme : depth0),(depth0 != null ? depth0.anchor : depth0),(depth0 != null ? depth0.className : depth0),(depth0 != null ? depth0.pageName : depth0),{"name":"parentClass","hash":{},"data":data}))
    + " "
    + alias3((helpers.style || (depth0 && depth0.style) || alias2).call(alias1,"color background-color","textColor templateBgColor",(depth0 != null ? depth0.theme : depth0),(depth0 != null ? depth0.defaultCss : depth0),null,(depth0 != null ? depth0.css : depth0),{"name":"style","hash":{},"data":data}))
    + ">\n    <div class=\"highlights-page_container\">\n        <div class=\"highlights-page_header\">\n            <h2 class=\"highlights-page_title section_title\" "
    + alias3((helpers.style || (depth0 && depth0.style) || alias2).call(alias1,"color","titleColor",(depth0 != null ? depth0.theme : depth0),(depth0 != null ? depth0.defaultCss : depth0),null,null,{"name":"style","hash":{},"data":data}))
    + ">"
    + alias3(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h2>\n        </div>\n        <div class=\"highlights-page_body\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.details : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n</div>";
},"useData":true,"useDepths":true});