import Handlebars from "handlebars/runtime";
import StyleString from 'to-style';
import Html from '../html-templates';
import JSONData from '../app';
import ddd from 'jquery.dotdotdot';
import Config from '../../config';

let sections = [];
export const getPageName = (data) => {
    data.forEach((item) => {
        if (item.pageName) {
            const section = {
                title: item.pageName,
                link: item.pageName.split(' ').join('-')
            };
            sections.push(section);
        } else if (item.title) {
            const section = {
                title: item.title,
                link: item.title.split(' ').join('-')
            };
            sections.push(section);
        }
    });
};

/**
 * orientation[string] - the orientation of a container 'vertical' or 'horizontal'
 * alignment[string] - the vertical and horizontal alignment of a container; ie. 'center between'
 * vertical alignment values 'top', 'vcenter', 'bottom', 'vbetween'
 * horizontal alignment values 'left' 'center', 'right', 'between'
 */
Handlebars.registerHelper('alignmentOrientation', (orientation, alignment) => {
    const isVertical = orientation && orientation === "vertical";
    const direction = isVertical ? 'flex-vertical' : 'flex-horizontal';
    const valign = alignment && alignment.split(' ')[0] || 'top';
    const align = alignment && alignment.split(' ')[1] || 'left';

    const classes = `${direction}${isVertical ? '-just-' : '-align-'}${align} ${direction}${isVertical ? '-align-' : '-just-'}${valign}`;
    return new Handlebars.SafeString(classes);
});

/**
 * item[object] - the object to be parsed
 * theme[string] - parent theme ('dark' or 'light') to be inherited by item
 * defaultCss[object] - parent css object to be inherited by item
 */
Handlebars.registerHelper('htmlParser', (item, theme, defaultCss) => {

    // pass parent theme and defaultCss objects
    item.theme = !theme ? '' : theme;
    item.defaultCss = !defaultCss ? '' : defaultCss;

    // only used for scrollable type
    if (item.type === "scrollable")
        item.useMobileTemplate = !item.mobileScroll && window.innerWidth <= Config.disableFullpageOn;

    // only used for navList type
    if (item.type === "navList")
        item.sections = sections || [];

    // checks to see if a passed item type is supported
    if (Html.hasOwnProperty(item.type)) {
        const parsedHtml = Html[item.type](item);
        return parsedHtml === null ? "" : new Handlebars.SafeString(parsedHtml);
    } else {
        console.error(`${item.type} is not supported`);
        return '';
    }
});