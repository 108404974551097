import Event from '../html-templates/event.handlebars';
import Navigation from '../html-templates/navigation.handlebars';
import NavigationList from '../html-templates/navigation-list.handlebars';
import Span from '../html-templates/span.handlebars';
import Paragraph from '../html-templates/paragraph.handlebars';
import H3 from '../html-templates/h3.handlebars';
import Html from '../html-templates/html.handlebars';
import List from '../html-templates/list.handlebars';
import Grid from '../html-templates/grid.handlebars';
import Image from '../html-templates/image.handlebars';
import Button from '../html-templates/button.handlebars';
import Title from '../html-templates/title.handlebars';
import Table from '../html-templates/table.handlebars';
import Figure from '../html-templates/figure.handlebars';
import Scrollable from '../html-templates/scrollable.handlebars';
import Box from '../html-templates/box.handlebars';
import Language from '../html-templates/language-toggle.handlebars';

const htmlTemplates = {
    event: Event,
    nav: Navigation,
    navList: NavigationList,
    span: Span,
    text: Paragraph,
    h3: H3,
    html: Html,
    list: List,
    grid: Grid,
    image: Image,
    button: Button,
    title: Title,
    table: Table,
    figure: Figure,
    scrollable: Scrollable,
    box: Box,
    language: Language
};

export default htmlTemplates;