import {
    customCss,
    getProxyData,
    addSectionContainers,
    initFullpage,
    getParameters,
    initFancybox,
    initPlugins,
    initCountdown,
    ieChecker,
    addIPScrollClass
} from './functions';
import {
    onNomineesMenuClick,
    clickHandlers,
    onTableTabClick,
    onTabClick,
    onBtnHover,
    onMenuHover,
    onScrollHandlers,
    onNomineeKeyEnter,
    navHandlers,
    popupHandlers
} from './handlers';
import '../sass/app.scss';
import Header from '../templates/header.handlebars';
import CookieBanner from '../templates/cookie-banner.handlebars';
import {getPageName} from './helpers/htmlHelpers';

const $head = $('head');
const $body = $('body');

if (ieChecker()) {
    alert('Your Web browser will have problems displaying this web page. For better viewing please update your browser to its latest version or use a different browser');
}

getProxyData(getParameters(), (err, proxyData) => {

    if (err) {
        console.error(err);
        return false
    }

    // Change proxy title
    if (proxyData && proxyData.title) {
        $head.find('title').text(proxyData.title);
    }

    // If Custom Css exists, append to head
    if (proxyData && proxyData.customCss) {
        $head.append(`<style>${customCss(proxyData.customCss)}</style>`);
    }

    // Append imported css file
    if (proxyData && proxyData.importCss) {
        $head.append(`<link type="text/css" rel="stylesheet" href="${proxyData.importCss}"/>`);
    }

    // Add favicon
    if (proxyData && proxyData.favicon) {
        $head.append(`<link type="image/x-icon" rel="shortcut icon" href="${proxyData.favicon}"/>`);
    }

    // Pass section object to build menu links for navigation
    getPageName(proxyData.section);

    // Render Header
    if (proxyData && proxyData.header) {
        $body.prepend(Header(proxyData)).addClass('has-header');
        proxyData.header.defaultCss = proxyData.defaultCss;
    }

    // Render Cookie Popup
    if (proxyData && proxyData.cookieBanner && document.cookie.indexOf('cookie_status') === -1) {
        if (typeof proxyData.cookieBanner === 'boolean') {
            proxyData.cookieBanner = {
                message: {
                    text: 'This website uses cookies to ensure you get the best experience on our website.'
                },
                button: {
                    text: 'Accept'
                }
            }
        }
        $body.prepend(CookieBanner(proxyData));
    }

    // Add Skip to main content link for accessibility
    $body.prepend(`<article><a href="#main" class="sr-only skip-main">Skip to main content</a></article>`);

    // Render Sections
    const hasSections = addSectionContainers(proxyData);

    if (hasSections) {
        // Initialize Plugins
        initFullpage(proxyData);
        initFancybox(proxyData);
        initPlugins();
        initCountdown(proxyData);
        // Start Event Listeners
        clickHandlers(proxyData);
        onNomineesMenuClick(proxyData);
        onTabClick(proxyData);
        onTableTabClick(proxyData);
        onBtnHover(proxyData);
        onMenuHover(proxyData);
        onNomineeKeyEnter();
        // Start Navigation Handlers
        navHandlers(proxyData);
        // Start Scroll Listeners
        onScrollHandlers();
        // Add IP native scroll class
        addIPScrollClass(proxyData);

        // Cookie Banner Clicks
        popupHandlers(proxyData);
    } else {
        $body.html('No valid sections added');
    }

    //Configure config
    if (proxyData && proxyData.config) {
        const config = proxyData.config;

        if (config.htmlLang) {
            $('html').attr('lang', config.htmlLang);
        }
        // Append Bugherd
        if (config.bugherdAPI) {
            $body.append(`<script type="text/javascript" src="https://www.bugherd.com/sidebarv2.js?apikey=${config.bugherdAPI}" defer></script>`);
        }

        // Append Google Analytics
        if (config.proxyAnalyticsUACode || config.clientAnalyticsUACode) {
            const proxyCode = !config.proxyAnalyticsUACode ? '' : (`gtag('config', '${config.proxyAnalyticsUACode}' ,{
                'page_title': '${document.title}',
                'page_location': '${window.location.href}',
                'anonymize_ip': true
            });`);
            const clientCode = !config.clientAnalyticsUACode ? '' : (`gtag('config', '${config.clientAnalyticsUACode}' ,{
                'page_title': '${document.title}',
                'page_location': '${window.location.href}',
                'anonymize_ip': true
            });`);

            $head.append(`<script async src="https://www.googletagmanager.com/gtag/js?id=${config.proxyAnalyticsUACode || config.clientAnalyticsUACode}"></script>`);
            $body.append(
                `<script>` +
                    `window.dataLayer = window.dataLayer || [];` +
                    `function gtag() {` +
                        `dataLayer.push(arguments);` +
                    `}` +
                    `gtag('js', new Date());` +
                    proxyCode +
                    clientCode +
                `</script>`
            );
        }

        // Append Google Tag Manager
        if (config.proxyTagManagerCode || config.clientTagManagerCode) {
            const noScriptString = (code) => {
                return (
                    `<!-- Google Tag Manager (noscript) -->` +
                    `<noscript aria-hidden="true"><iframe src="https://www.googletagmanager.com/ns.html?id=${code}"` +
                    `height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>` +
                    `<!-- End Google Tag Manager (noscript) -->`
                )
            }

            const scriptString = (code) => {
                return (
                    `<!-- Google Tag Manager -->` +
                    `<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':` +
                    `new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],` +
                    `j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=` +
                    `'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);` +
                    `})(window,document,'script','dataLayer','${code}');</script>` +
                    `<!-- End Google Tag Manager -->`
                );
            }

            const proxyTagScript = !config.proxyTagManagerCode ? '' : scriptString(config.proxyTagManagerCode);
            const proxyTag = !config.proxyTagManagerCode ? '' : noScriptString(config.proxyTagManagerCode);
            const clientTagScript = !config.clientTagManagerCode ? '' : scriptString(config.clientTagManagerCode);
            const clientTag = !config.clientTagManagerCode ? '' : noScriptString(config.clientTagManagerCode);

            $head.append(
                proxyTagScript +
                clientTagScript
            );

            $body.prepend(
                proxyTag +
                clientTag
            );
        }
    }
});