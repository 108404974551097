var Handlebars = require("/home/rof/src/github.com/q4mobile/interactive-proxy/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return " header--"
    + container.escapeExpression(((helper = (helper = helpers.theme || (depth0 != null ? depth0.theme : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"theme","hash":{},"data":data}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression((helpers.customStyle || (depth0 && depth0.customStyle) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.header : depth0)) != null ? stack1.css : stack1),{"name":"customStyle","hash":{},"data":data}));
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "            <div class=\"header_logo-container\">\n                <div class=\"header_logo\">\n                    <a href=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.header : depth0)) != null ? stack1.logo : stack1)) != null ? stack1.link : stack1), depth0))
    + "\" target=\"_blank\"><img src=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.header : depth0)) != null ? stack1.logo : stack1)) != null ? stack1.href : stack1), depth0))
    + "\" alt=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.header : depth0)) != null ? stack1.logo : stack1)) != null ? stack1.alt : stack1), depth0))
    + "\"/><span class=\"sr-only\">Company Logo Link (opens in a new window)</span></a>\n                </div>\n            </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <div class=\"header_sidebar\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.header : depth0)) != null ? stack1.items : stack1),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + container.escapeExpression((helpers.htmlParser || (depth0 && depth0.htmlParser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,(depth0 != null ? depth0.theme : depth0),((stack1 = (data && data.root)) && stack1.defaultCss),{"name":"htmlParser","hash":{},"data":data}))
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<header id=\"interactive-header\">\n    <div class=\"header"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.theme : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.header : depth0)) != null ? stack1.css : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.header : depth0)) != null ? stack1.logo : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.header : depth0)) != null ? stack1.items : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n</header>";
},"useData":true});