var Handlebars = require("/home/rof/src/github.com/q4mobile/interactive-proxy/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "    <div class=\"content-page_scrollable--non-scroll"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.className : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" style=\"height:auto !important;"
    + container.escapeExpression((helpers.cssToString || (depth0 && depth0.cssToString) || helpers.helperMissing).call(alias1,(depth0 != null ? depth0.css : depth0),{"name":"cssToString","hash":{},"data":data}))
    + "\">\n        <div class=\"content-page_scrollable-container-inner\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper;

  return " "
    + container.escapeExpression(((helper = (helper = helpers.className || (depth0 != null ? depth0.className : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"className","hash":{},"data":data}) : helper)));
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "               "
    + container.escapeExpression((helpers.htmlParser || (depth0 && depth0.htmlParser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,((stack1 = (data && data.root)) && stack1.theme),((stack1 = (data && data.root)) && stack1.defaultCss),{"name":"htmlParser","hash":{},"data":data}))
    + "\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "    <div class=\"content-page_scrollable"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.className : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" style=\"height:"
    + alias3(((helper = (helper = helpers.height || (depth0 != null ? depth0.height : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"height","hash":{},"data":data}) : helper)))
    + ";"
    + alias3((helpers.cssToString || (depth0 && depth0.cssToString) || alias2).call(alias1,(depth0 != null ? depth0.css : depth0),{"name":"cssToString","hash":{},"data":data}))
    + "\">\n        <div class=\"content-page_scrollable-overlay\"></div>\n        <div class=\"content-page_scrollable-container"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.scrollbarWrapper : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n            <div class=\"content-page_scrollable-container-inner"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.truncate : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.truncate : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            </div>\n        </div>\n    </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper;

  return " scrollbar-wrapper "
    + container.escapeExpression(((helper = (helper = helpers.scrollbarWrapper || (depth0 != null ? depth0.scrollbarWrapper : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"scrollbarWrapper","hash":{},"data":data}) : helper)));
},"9":function(container,depth0,helpers,partials,data) {
    return " ddd-container";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + container.escapeExpression((helpers.htmlParser || (depth0 && depth0.htmlParser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,((stack1 = (data && data.root)) && stack1.theme),((stack1 = (data && data.root)) && stack1.defaultCss),{"name":"htmlParser","hash":{},"data":data}))
    + "\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "<a role=\"button\" tabindex=\"0\" class=\"ddd-toggle ddd-keep\"><span class=\"sr-only\">Toggles truncation of text</span></a>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.useMobileTemplate : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});