var Handlebars = require("/home/rof/src/github.com/q4mobile/interactive-proxy/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " content-page_grid--column";
},"3":function(container,depth0,helpers,partials,data) {
    return " content-page_grid--row";
},"5":function(container,depth0,helpers,partials,data) {
    return " content-page_grid--no-border";
},"7":function(container,depth0,helpers,partials,data) {
    return " content-page_grid--no-padding";
},"9":function(container,depth0,helpers,partials,data) {
    var helper;

  return " "
    + container.escapeExpression(((helper = (helper = helpers.className || (depth0 != null ? depth0.className : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"className","hash":{},"data":data}) : helper)));
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <div class=\"content-page_grid-row"
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].border : depths[1]),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.program(5, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.padding : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.program(14, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "\"\n             style=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.gridWidth : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.padding : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.paddingColor : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n            "
    + container.escapeExpression((helpers.htmlParser || (depth0 && depth0.htmlParser) || helpers.helperMissing).call(alias1,depth0,(depths[1] != null ? depths[1].theme : depths[1]),((stack1 = (data && data.root)) && stack1.defaultCss),{"name":"htmlParser","hash":{},"data":data}))
    + "\n        </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "";
},"14":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? depths[1].padding : depths[1]),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.program(7, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    var helper;

  return "flex-basis:"
    + container.escapeExpression(((helper = (helper = helpers.gridWidth || (depth0 != null ? depth0.gridWidth : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"gridWidth","hash":{},"data":data}) : helper)))
    + ";flex-shrink:0;";
},"18":function(container,depth0,helpers,partials,data) {
    var helper;

  return "padding:"
    + container.escapeExpression(((helper = (helper = helpers.padding || (depth0 != null ? depth0.padding : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"padding","hash":{},"data":data}) : helper)))
    + ";";
},"20":function(container,depth0,helpers,partials,data) {
    var helper;

  return "background-color:"
    + container.escapeExpression(((helper = (helper = helpers.paddingColor || (depth0 != null ? depth0.paddingColor : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"paddingColor","hash":{},"data":data}) : helper)));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<div class=\"content-page_grid"
    + ((stack1 = (helpers.ifCondition || (depth0 && depth0.ifCondition) || alias2).call(alias1,"vertical",(depth0 != null ? depth0.orientation : depth0),{"name":"ifCondition","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.border : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.padding : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.className : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" "
    + container.escapeExpression((helpers.style || (depth0 && depth0.style) || alias2).call(alias1,"color","textColor",(depth0 != null ? depth0.theme : depth0),(depth0 != null ? depth0.defaultCss : depth0),(depth0 != null ? depth0.width : depth0),(depth0 != null ? depth0.css : depth0),{"name":"style","hash":{},"data":data}))
    + ">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true,"useDepths":true});