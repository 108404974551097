var Handlebars = require("/home/rof/src/github.com/q4mobile/interactive-proxy/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "    <div class=\"event"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.layout : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.className : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.openOnLoad : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" "
    + alias3((helpers.style || (depth0 && depth0.style) || alias2).call(alias1,"background-color","eventBgColor",(depth0 != null ? depth0.theme : depth0),(depth0 != null ? depth0.defaultCss : depth0),null,(depth0 != null ? depth0.css : depth0),{"name":"style","hash":{},"data":data}))
    + ">\n        <div class=\"event_info\">\n            <span class=\"event_info-meeting\">"
    + alias3(((helper = (helper = helpers.overviewText || (depth0 != null ? depth0.overviewText : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"overviewText","hash":{},"data":data}) : helper)))
    + "</span>\n            <span role=\"button\" tabindex=\"0\" class=\"event_info-btn"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.layout : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.openOnLoad : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" "
    + alias3((helpers.style || (depth0 && depth0.style) || alias2).call(alias1,"color","eventLinkColorMobile",(depth0 != null ? depth0.theme : depth0),(depth0 != null ? depth0.defaultCss : depth0),null,(depth0 != null ? depth0.css : depth0),{"name":"style","hash":{},"data":data}))
    + "><span\n                    class=\"event_info-btn-text\">"
    + alias3(((helper = (helper = helpers.detailButtonText || (depth0 != null ? depth0.detailButtonText : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"detailButtonText","hash":{},"data":data}) : helper)))
    + "</span></span>\n        </div>\n        <div class=\"event_countdown-expired\">"
    + ((stack1 = ((helper = (helper = helpers.expiryMessage || (depth0 != null ? depth0.expiryMessage : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"expiryMessage","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n        <div class=\"event_countdown\">\n            <div class=\"event_countdown-numbers countdown\">"
    + alias3(((helper = (helper = helpers.fullDate || (depth0 != null ? depth0.fullDate : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"fullDate","hash":{},"data":data}) : helper)))
    + "</div>\n            <div class=\"event_countdown-days\">\n                <span>Days</span>\n                <span>Hrs</span>\n                <span>Min</span>\n            </div>\n        </div>\n        <div class=\"event_details\">\n            <span role=\"button\" tabindex=\"0\" class=\"event_details-close-btn q4proxy-close-4pt\"></span>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.date : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.time : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.location : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.record : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.brief : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.webcastLink : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.program(27, data, 0),"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.howToVote : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.voteNowLink : depth0),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.googleMapEmbedLink : depth0),{"name":"if","hash":{},"fn":container.program(45, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper;

  return " event--"
    + container.escapeExpression(((helper = (helper = helpers.layout || (depth0 != null ? depth0.layout : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"layout","hash":{},"data":data}) : helper)));
},"4":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.className || (depth0 != null ? depth0.className : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"className","hash":{},"data":data}) : helper)));
},"6":function(container,depth0,helpers,partials,data) {
    return "event--details-open";
},"8":function(container,depth0,helpers,partials,data) {
    var helper;

  return " "
    + container.escapeExpression(((helper = (helper = helpers.layout || (depth0 != null ? depth0.layout : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"layout","hash":{},"data":data}) : helper)));
},"10":function(container,depth0,helpers,partials,data) {
    return "event_info-btn--active";
},"12":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                <div class=\"event_details-section\">\n                    <div class=\"event_details-section-row\">\n                        <span class=\"event_details-section-label\">Date</span>\n                        <span>"
    + container.escapeExpression(((helper = (helper = helpers.date || (depth0 != null ? depth0.date : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"date","hash":{},"data":data}) : helper)))
    + "</span>\n                    </div>\n                </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                <div class=\"event_details-section\">\n                    <div class=\"event_details-section-row\">\n                        <span class=\"event_details-section-label\">Time</span>\n                        <span>"
    + alias4(((helper = (helper = helpers.time || (depth0 != null ? depth0.time : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"time","hash":{},"data":data}) : helper)))
    + " "
    + alias4(((helper = (helper = helpers.timeZone || (depth0 != null ? depth0.timeZone : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"timeZone","hash":{},"data":data}) : helper)))
    + "</span>\n                    </div>\n                </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "                <div class=\"event_details-section\">\n                    <div class=\"event_details-section-row\">\n                        <span class=\"event_details-section-label\">Location</span>\n                        <span>"
    + ((stack1 = ((helper = (helper = helpers.location || (depth0 != null ? depth0.location : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"location","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\n                    </div>\n                </div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "                <div class=\"event_details-section\">\n                    <div class=\"event_details-section-row\">\n                        <span class=\"event_details-section-label\">Record Date</span>\n                        <span>"
    + ((stack1 = ((helper = (helper = helpers.record || (depth0 != null ? depth0.record : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"record","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\n                    </div>\n                </div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "                <div class=\"event_details-section\">\n                    <div class=\"event_details-section-row\">\n                        <span>"
    + ((stack1 = ((helper = (helper = helpers.brief || (depth0 != null ? depth0.brief : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"brief","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\n                    </div>\n                </div>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                <div class=\"event_details-section\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.webcastLink : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.externalLink : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                        <div class=\"event_details-section-row\">\n                            <span class=\"event_details-section-label\">Audio Webcast</span>\n                            <span><a href=\""
    + alias4(((helper = (helper = helpers.webcastLink || (depth0 != null ? depth0.webcastLink : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"webcastLink","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">"
    + alias4(((helper = (helper = helpers.webcastLink || (depth0 != null ? depth0.webcastLink : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"webcastLink","hash":{},"data":data}) : helper)))
    + "</a></span>\n                        </div>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                        <div class=\"event_details-section-row\">\n                            <span class=\"event_details-section-label\">View Online</span>\n                            <span><a href=\""
    + alias4(((helper = (helper = helpers.externalLink || (depth0 != null ? depth0.externalLink : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"externalLink","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">"
    + alias4(((helper = (helper = helpers.externalLink || (depth0 != null ? depth0.externalLink : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"externalLink","hash":{},"data":data}) : helper)))
    + "</a></span>\n                        </div>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.externalLink : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                    <div class=\"event_details-section\">\n                        <div class=\"event_details-section-row\">\n                            <span class=\"event_details-section-label\">View Online</span>\n                            <span><a href=\""
    + alias4(((helper = (helper = helpers.externalLink || (depth0 != null ? depth0.externalLink : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"externalLink","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">"
    + alias4(((helper = (helper = helpers.externalLink || (depth0 != null ? depth0.externalLink : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"externalLink","hash":{},"data":data}) : helper)))
    + "</a></span>\n                        </div>\n                    </div>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                <div class=\"event_details-section\">\n                    <div class=\"event_details-section-row\">\n                        <span class=\"event_details-section-label\">"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.howToVote : depth0)) != null ? stack1.text : stack1),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.program(33, data, 0),"data":data})) != null ? stack1 : "")
    + "</span>\n                        <span class=\"event_details-vote-link\"><a\n                                data-href=\""
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.howToVote : depth0)) != null ? stack1.link : stack1),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.program(37, data, 0),"data":data})) != null ? stack1 : "")
    + "\" role=\"button\" tabindex=\"0\" "
    + container.escapeExpression((helpers.style || (depth0 && depth0.style) || helpers.helperMissing).call(alias1,"color","eventLinkColorMobile",(depth0 != null ? depth0.theme : depth0),(depth0 != null ? depth0.defaultCss : depth0),null,(depth0 != null ? depth0.css : depth0),{"name":"style","hash":{},"data":data}))
    + ">"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.howToVote : depth0)) != null ? stack1.textLink : stack1),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.program(41, data, 0),"data":data})) != null ? stack1 : "")
    + "</a></span>\n                    </div>\n                </div>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.lambda(((stack1 = (depth0 != null ? depth0.howToVote : depth0)) != null ? stack1.text : stack1), depth0)) != null ? stack1 : "");
},"33":function(container,depth0,helpers,partials,data) {
    return "How to Vote";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.howToVote : depth0)) != null ? stack1.link : stack1), depth0));
},"37":function(container,depth0,helpers,partials,data) {
    return "#How-to-Vote";
},"39":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.lambda(((stack1 = (depth0 != null ? depth0.howToVote : depth0)) != null ? stack1.textLink : stack1), depth0)) != null ? stack1 : "");
},"41":function(container,depth0,helpers,partials,data) {
    return "Go now";
},"43":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                <div class=\"event_details-section\">\n                    <div class=\"event_details-section-row\">\n                        <span class=\"event_details-vote-now-link\">\n                            <a href=\""
    + alias3(((helper = (helper = helpers.voteNowLink || (depth0 != null ? depth0.voteNowLink : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"voteNowLink","hash":{},"data":data}) : helper)))
    + "\" class=\"event_details-btn btn\" "
    + alias3((helpers.style || (depth0 && depth0.style) || alias2).call(alias1,"border-color background-color color","btnBorderColor btnBgColor btnTextColor",((stack1 = (data && data.root)) && stack1.theme),((stack1 = (data && data.root)) && stack1.defaultCss),null,null,{"name":"style","hash":{},"data":data}))
    + ">Vote Now</a>\n                        </span>\n                    </div>\n                </div>\n";
},"45":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                <div class=\"event_details-section event_details-section--map\">\n                    <iframe class=\"event_details-map\" src=\""
    + container.escapeExpression(((helper = (helper = helpers.googleMapEmbedLink || (depth0 != null ? depth0.googleMapEmbedLink : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"googleMapEmbedLink","hash":{},"data":data}) : helper)))
    + "\" title=\"Event Google Map Location\"></iframe>\n                </div>\n";
},"47":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "    <div class=\"event "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.layout : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.className : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.openOnLoad : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.openOnLoad : depth0),{"name":"if","hash":{},"fn":container.program(48, data, 0),"inverse":container.program(50, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n        <div class=\"event_countdown-expired\">"
    + ((stack1 = ((helper = (helper = helpers.expiryMessage || (depth0 != null ? depth0.expiryMessage : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"expiryMessage","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n        <span class=\"event_text\">"
    + alias4(((helper = (helper = helpers.overviewText || (depth0 != null ? depth0.overviewText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"overviewText","hash":{},"data":data}) : helper)))
    + "</span>\n        <span class=\"event_countdown countdown\">"
    + alias4(((helper = (helper = helpers.fullDate || (depth0 != null ? depth0.fullDate : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fullDate","hash":{},"data":data}) : helper)))
    + "</span>\n        <span class=\"event_info-btn "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.openOnLoad : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" role=\"button\" tabindex=\"0\"><span class=\"event_info-btn-text\">"
    + alias4(((helper = (helper = helpers.detailButtonText || (depth0 != null ? depth0.detailButtonText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"detailButtonText","hash":{},"data":data}) : helper)))
    + "</span></span>\n        <div class=\"event_details\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.date : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.time : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.location : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.record : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.brief : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.webcastLink : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.program(27, data, 0),"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.howToVote : depth0),{"name":"if","hash":{},"fn":container.program(52, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.voteNowLink : depth0),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.googleMapEmbedLink : depth0),{"name":"if","hash":{},"fn":container.program(45, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n    </div>\n";
},"48":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.style || (depth0 && depth0.style) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"background-color","eventBgColor",(depth0 != null ? depth0.theme : depth0),(depth0 != null ? depth0.defaultCss : depth0),null,(depth0 != null ? depth0.css : depth0),{"name":"style","hash":{},"data":data}));
},"50":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.customStyle || (depth0 && depth0.customStyle) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.css : depth0),{"name":"customStyle","hash":{},"data":data}));
},"52":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                <div class=\"event_details-section\">\n                    <div class=\"event_details-section-row\">\n                        <span class=\"event_details-section-label\">"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.howToVote : depth0)) != null ? stack1.text : stack1),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.program(33, data, 0),"data":data})) != null ? stack1 : "")
    + "</span>\n                        <span class=\"event_details-vote-link\"><a\n                                data-href=\""
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.howToVote : depth0)) != null ? stack1.link : stack1),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.program(37, data, 0),"data":data})) != null ? stack1 : "")
    + "\" role=\"button\" tabindex=\"0\">"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.howToVote : depth0)) != null ? stack1.textLink : stack1),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.program(41, data, 0),"data":data})) != null ? stack1 : "")
    + "</a></span>\n                    </div>\n                </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return ((stack1 = (helpers.ifCondition || (depth0 && depth0.ifCondition) || alias2).call(alias1,"compressed",(depth0 != null ? depth0.layout : depth0),{"name":"ifCondition","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.ifCondition || (depth0 && depth0.ifCondition) || alias2).call(alias1,"extended",(depth0 != null ? depth0.layout : depth0),{"name":"ifCondition","hash":{},"fn":container.program(47, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});