var Handlebars = require("/home/rof/src/github.com/q4mobile/interactive-proxy/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "                <div class=\"index-page_section\">\n                    <ul class=\"index-page_section-list\">\n                        <li class=\"index-page_section-heading\" "
    + alias3((helpers.style || (depth0 && depth0.style) || alias2).call(alias1,"color","indexHeadingTextColor",(depths[1] != null ? depths[1].theme : depths[1]),(depths[1] != null ? depths[1].defaultCss : depths[1]),null,null,{"name":"style","hash":{},"data":data}))
    + ">\n                            <a target=\"_blank\" class=\"index-page_heading-link\" href=\""
    + alias3(container.lambda((depths[1] != null ? depths[1].documentUrl : depths[1]), depth0))
    + "#page="
    + alias3(((helper = (helper = helpers.page || (depth0 != null ? depth0.page : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"page","hash":{},"data":data}) : helper)))
    + "\">"
    + alias3(((helper = (helper = helpers.heading || (depth0 != null ? depth0.heading : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"heading","hash":{},"data":data}) : helper)))
    + "</a>\n                        </li>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                    </ul>\n                </div>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=helpers.helperMissing, alias4="function";

  return "                                <li>\n                                    <a target=\"_blank\" class=\"index-page_link\" href=\""
    + alias1(container.lambda((depths[2] != null ? depths[2].documentUrl : depths[2]), depth0))
    + "#page="
    + alias1(((helper = (helper = helpers.page || (depth0 != null ? depth0.page : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"page","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">"
    + alias1(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"title","hash":{},"data":data}) : helper)))
    + "</a>\n                                </li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div "
    + alias3((helpers.parentClass || (depth0 && depth0.parentClass) || alias2).call(alias1,(depth0 != null ? depth0.template : depth0),(depth0 != null ? depth0.theme : depth0),(depth0 != null ? depth0.anchor : depth0),(depth0 != null ? depth0.className : depth0),(depth0 != null ? depth0.pageName : depth0),{"name":"parentClass","hash":{},"data":data}))
    + " "
    + alias3((helpers.style || (depth0 && depth0.style) || alias2).call(alias1,"color background-color","textColor templateBgColor",(depth0 != null ? depth0.theme : depth0),(depth0 != null ? depth0.defaultCss : depth0),null,(depth0 != null ? depth0.css : depth0),{"name":"style","hash":{},"data":data}))
    + ">\n    <div class=\"index-page_container\">\n        <div class=\"index-page_header\">\n            <h2 class=\"index-page_title section_title\" "
    + alias3((helpers.style || (depth0 && depth0.style) || alias2).call(alias1,"color","titleColor",(depth0 != null ? depth0.theme : depth0),(depth0 != null ? depth0.defaultCss : depth0),null,null,{"name":"style","hash":{},"data":data}))
    + ">"
    + alias3(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h2>\n        </div>\n        <div class=\"index-page_body\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.groups : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"index-page_footer\">\n            <span class=\"powered-by-Q4\"><a href=\"http://q4inc.com/Powered-by-Q4/\" target=\"_blank\">Powered by Q4 Inc.</a></span>\n        </div>\n    </div>\n</div>";
},"useData":true,"useDepths":true});