const templates = require('./templates');
const Fullpage = require('fullpage.js/dist/fullpage.extensions.min');
const Config = require('../config');
const Handlers = require('./handlers');
require('superfish');
require('fullpage.js/vendors/scrolloverflow');
require('./helpers/all');

module.exports = {
    /**
     * Get data.json from specified client through url param
     * @type function
     * @param client[object] - client object contains parameters from url
     * @param callback[function]
     */
    getProxyData: (client, callback) => {
        const name = client.name || 'local';
        const namePath = `/${name}`;
        const yearPath = client.year ? `/${client.year}` : '';
        const langPath = client.lang ? `/lang/${client.lang}` : '';

        let url;
        switch (process.env.NODE_ENV) {
            case 'none':
                url = `${Config.proxyData}`;
                break;
            case 'development':
                url = `${Config.devFilePath}`;
                break;
            case 'production':
                url = `${Config.prodFilePath}`;
                break;
            default:
                url = `${Config.proxyData}`;
                break;
        }

        url = `${url}${namePath}${yearPath}${langPath}/data.json`;

        $.ajax({
            method: 'GET',
            url: url,
            dataType: 'json'
        }).done((data) => {
            if (!data) {
                callback(new Error('No data available'));
            }
            callback(null, data);
        }).fail(() => {
            callback(new Error('No data available'));
        })
    },
    /**
     * Creates the page templates and appends to DOM
     * @type function
     * @param data[object] - object from data.json
     * @returns {number} - returns number of valid sections added
     */
    addSectionContainers: (data) => {
        const containers = [];
        data.section.forEach((section, i) => {
            switch (section.template) {
                case "directors":
                    section = $.extend(section, createNav(section.directors));
                    break;
                default:
                    break;
            }
            section.defaultCss = data.defaultCss;
            if (section && section.pageName) {
                section.anchor = section.pageName.split(" ").join("-");
            } else if (section && section.title) {
                section.anchor = section.title.split(" ").join("-");
            }
            if (templates && templates.default[section.template]) {
                containers.push(templates.default[section.template](section));
            }
        });
        $('#interactive-proxy').html(containers.join('')).find('.section').first().addClass('fp-visited');

        return containers.length;
    },
    /**
     * Initializes Fullpage.js
     * @type function
     * @param data
     */
    initFullpage: (data) => {
        data = !typeof data ? {} : data;
        data.config = !typeof data.config ? {} : data.config;

        const sections = data.section || [];
        const nativeScroll = !typeof data.config.useNativeScroll ? Config.useNativeScroll : data.config.useNativeScroll;
        const fpRespWidth = !typeof data.config.responsiveWidth ? Config.responsiveWidth : data.config.responsiveWidth;
        const fpRespHeight = !typeof data.config.responsiveHeight ? Config.responsiveHeight : data.config.responsiveHeight;
        const disableFPOn = !typeof data.config.disableFullpageOn ? Config.disableFullpageOn : data.config.disableFullpageOn;
        const disableFPScrolling = !typeof data.config.disableFullpageScrolling ? Config.disableFullpageScrolling : data.config.disableFullpageScrolling;
        const device = checkResponsive(fpRespWidth, fpRespHeight, disableFPOn);

        let tooltips = [];
        let dataAnchors = [];

        sections.forEach((section) => {
            if (section && section.pageName) {
                tooltips.push(section.pageName);
                dataAnchors.push(section.anchor);
            } else if (section && section.title) {
                tooltips.push(section.title);
                dataAnchors.push(section.anchor);
            }
        });

        const fp = (scrollOverFlow, autoScroll) => {
            new Fullpage('#interactive-proxy', {
                sectionSelector: '.section',
                dragAndMove: false,
                fitToSection: false,
                verticalCentered: false,
                anchors: dataAnchors,
                navigation: true,
                autoScrolling: autoScroll,
                navigationPosition: 'right',
                navigationTooltips: tooltips,
                normalScrollElements: '.fp-normal-scroll',
                responsiveWidth: fpRespWidth,
                responsiveHeight: fpRespHeight,
                scrollOverflow: nativeScroll ? false : scrollOverFlow,
                scrollOverflowReset: true,
                scrollOverflowOptions: {
                    mouseWheelSpeed: 5,
                    eventPassthrough: 'horizontal',
                    momentum: false,
                },
                onLeave: function (origin, destination, direction) {
                    const $btn = $('.event_info-btn');
                    const $header = $('.header');
                    const $footer = $('footer');

                    $btn.each(function () {
                        if (this.length !== 0 && $(this).attr('class').indexOf('active') > -1) {
                            $(this).trigger({
                                type: 'click',
                                which: 1
                            });
                        }
                    });

                    $(destination.item).addClass('fp-visited');

                    destination.index !== 0 ?
                        ($header.addClass('header--collapsed'), $footer.addClass('nav-overlay')) :
                        ($header.removeClass('header--collapsed'), $footer.removeClass('nav-overlay'));
                },
                afterLoad: function (origin, destination, direction) {
                    const $currentSection = $(destination.item);
                    const $body = $('body');
                    const $fpNav = $('#fp-nav');
                    const sectionHeight = $currentSection.innerHeight();
                    const containerHeight = $currentSection.find('[class*="page_container"]').innerHeight();

                    const $sectionScroller = $currentSection.find('.fp-scroller');
                    sectionHeight > containerHeight ? $sectionScroller.css('height', sectionHeight) : '';

                    $currentSection.attr('class').indexOf('--dark') > -1 ? $body.addClass('dark') : $body.removeClass('dark');
                    $currentSection.addClass('fp-animated');

                    containerHeight > sectionHeight && window.innerWidth > Config.responsiveWidth && window.innerHeight > Config.responsiveHeight && fullpage_api.reBuild();

                    $fpNav.find('li').removeClass('active-nav').find('a.active').parent().addClass('active-nav');

                    if (nativeScroll) Handlers.fpStopScroll($currentSection);
                },
                afterRender: function () {
                    const $fpNav = $('#fp-nav').attr('role', 'navigation');
                    const $fpNavLink = $fpNav.find('a').attr('role', 'button').attr('tabindex', '0').attr('aria-hidden', 'true').each(function (i, item) {
                        $(item).attr('data-href', $(item).attr('href'));
                    });
                    const $currentSection = $('.section');

                    $currentSection.each(function () {
                        const sectionHeight = $(this).innerHeight();
                        const $sectionScroller = $(this).find('.fp-scroller');
                        const containerHeight = $(this).find('[class*="page_container"]').innerHeight();

                        sectionHeight > containerHeight ? $sectionScroller.css('height', sectionHeight) : '';
                    });

                    $fpNavLink.hover(function () {
                            $(this).closest('ul').find('div').css('opacity', '0');
                            $(this).next().css('opacity', '1');
                        }, function () {
                            $(this).closest('ul').find('div').css('opacity', '');
                        }
                    );
                }
            });
        };

        // device === 'desktop' ? fp(true, true) : device === 'tablet' ? fp(false, false) : '';

        if (device === 'desktop' && !disableFPScrolling) {
            fp(true, true);
        } else if (device === 'tablet') {
            fp(false, false);
        } else if (disableFPScrolling) {
            $('#main').addClass('fpScroll-disabled');
            fp(false, false);
        }

        $(window).resize(function () {
            if (typeof fullpage_api === 'undefined' && device === 'desktop' && !disableFPScrolling) {
                fp(true, true);
            } else if (typeof fullpage_api === 'undefined' && device === 'tablet') {
                fp(false, false);
            }
            typeof fullpage_api === 'object' && fullpage_api.reBuild();
        });
    },
    /**
     * Initializes Fancybox
     * @type function
     * @param data[object] - object from data.json
     */
    initFancybox: (data) => {
        data = !data ? {} : data;
        data.config = !data.config ? {} : data.config;

        const css = !data || !data.defaultCss ? '' : data.defaultCss;
        const fancyboxButtonColor = !css.light || !css.light.fancyboxButtonColor ? 'inherit' : css.light.fancyboxButtonColor;
        const fancyboxHoverBackgroundColor = !css.light || !css.light.fancyboxHoverBgColor ? 'inherit' : css.light.fancyboxHoverBgColor;

        const fpRespWidth = !data.config.responsiveWidth ? Config.responsiveWidth : data.config.responsiveWidth;
        const fpRespHeight = !data.config.responsiveHeight ? Config.responsiveHeight : data.config.responsiveHeight;
        const disableFPOn = !data.config.disableFullpageOn ? Config.disableFullpageOn : data.config.disableFullpageOn;

        const device = checkResponsive(fpRespWidth, fpRespHeight, disableFPOn);

        $('[data-fancybox]').fancybox({
            type: 'inline',
            touch: device === 'desktop' ? false : {vertical: true},
            loop: true,
            autoFocus: false,
            animationDuration: 500,
            transitionEffect: 'slide',
            btnTpl: {
                smallBtn:
                    '<button type="button" data-fancybox-close class="fancybox-button fancybox-close-small" title="{{CLOSE}}">' +
                    '<span class="q4proxy-close-4pt"></span>' +
                    '</button>',
                arrowLeft:
                    '<button data-fancybox-prev class="fancybox-button fancybox-arrow fancybox-button--arrow_left" style="color:' + (fancyboxButtonColor || "inherit") + '" title="{{PREV}}">' +
                    '<span class="q4proxy-caret-sm-left-4pt"></span>' +
                    '</button>',

                arrowRight:
                    '<button data-fancybox-next class="fancybox-button fancybox-arrow fancybox-button--arrow_right" style="color:' + (fancyboxButtonColor || "inherit") + '" title="{{NEXT}}">' +
                    '<span class="q4proxy-caret-sm-right-4pt"></span>' +
                    "</button>",
            },
            beforeLoad: function (instance, slide) {
                $('html').addClass('fancybox-open');

                if (device === 'tablet' || device === 'phone') {
                    instance.opts.touch = true;
                }
                typeof fullpage_api === 'object' && fullpage_api.setAllowScrolling(false);
            },
            afterShow: function (instance, slide) {
                const $closeBtn = $('.fancybox-slide--current').find('.fancybox-close-small');
                const $arrows = $('.fancybox-navigation').find('.fancybox-arrow');

                if ($('.nominees-page_detail.fancybox-content')) {
                    $('.nominees-page_detail-personal-row--committee').each(function () {
                        if ($(this).find('.nominees-page_detail-personal-col--committee').text().length === 0) {
                            $(this).hide();
                        }
                    });
                }

                $closeBtn.hover(function () {
                    $(this).find('span').css('color', fancyboxHoverBackgroundColor);
                }, function () {
                    $(this).find('span').css('color', fancyboxButtonColor);
                });

                $arrows.hover(function () {
                    $(this).css({
                        color: "#fff",
                        background: fancyboxHoverBackgroundColor
                    });
                }, function () {
                    $(this).css({
                        color: fancyboxButtonColor,
                        background: "#fff"
                    });
                });
            },
            afterClose: function (instance, slide) {
                $('html').removeClass('fancybox-open');
                typeof fullpage_api === 'object' && fullpage_api.setAllowScrolling(true);
            }
        });
    },
    /**
     * @type function
     * Initializes other plugins used by proxy
     * Includes jquery.scrollbar, superfish and jquery.dotdotdot
     */
    initPlugins: () => {
        !/MSIE|Trident|Mozilla/i.test(navigator.userAgent) && $('.scrollbar-wrapper').scrollbar();
        /Chrome/i.test(navigator.userAgent) && $('.scrollbar-wrapper').scrollbar();
        /Mozilla/i.test(navigator.userAgent) && $('.content-page_scrollable-container.scrollbar-wrapper').scrollbar();
        $('ul.sf-menu').superfish({
            delay: 250
        });
        const $ddd = $('.ddd-container');
        const initDDD = () => $ddd.dotdotdot({
            height: $ddd.parent().innerHeight() * 0.5,
            keep: 'ddd-keep',
            watch: true,
            truncate: 'letter'
        });

        $(window).resize(function () {
            initDDD();
        });
        initDDD();
    },
    /**
     * Initializes jquery.countdown when an event date is available and known containers are added
     * @type function
     * @param data[object] - object from data.json
     */
    initCountdown: (data) => {

        $('.countdown').each(function (e) {
            const $countdown = $(this);
            const date = new Date($countdown.text());

            if (!(date instanceof Date && !isNaN(date))) {
                $countdown.hide();
                return
            }

            if ($countdown.hasClass('event_countdown-numbers')) {
                $countdown.countdown(date, function (event) {
                    if (event.type === 'finish') {
                        $countdown.closest('.event').addClass('event--expired');
                    } else {
                        $(this).html(event.strftime('<span>%D</span><span>%H</span><span>%M</span>'));
                    }
                });
            } else {
                $countdown.countdown(date, function (event) {
                    if (event.type === 'finish') {
                        $countdown.closest('.event').addClass('event--expired');
                    } else {
                        $(this).html(event.strftime('' +
                            '<div class="countdown_counter">' +
                            '<span class="countdown_number">%D </span>' +
                            '<span class="countdown_text">days</span>' +
                            '</div>' +
                            '<div class="countdown_counter">' +
                            '<span class="countdown_number"> %H </span>' +
                            '<span class="countdown_text">hrs</span>' +
                            '</div>' +
                            '<div class="countdown_counter">' +
                            '<span class="countdown_number"> %M </span>' +
                            '<span class="countdown_text">min</span>' +
                            '</div>'
                        ));
                    }
                });
            }
        });
    },
    /**
     * Gets the parameters from window's url and parses
     * @type function
     * @returns {object}
     */
    getParameters: () => {
        const search = location.search.substring(1);
        const pathParams = location.pathname.substring(1).split('/') || [];

        if (!search) {
            if (!pathParams) {
                return {};
            }
            return {
                name: pathParams[0] || '',
                year: pathParams[1] && !isNaN(pathParams[1]) ? pathParams[1] : '',
                lang: pathParams[1] && isNaN(pathParams[1]) ?
                    pathParams[1] :
                    (pathParams[2] || '')
            };
        }
        return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
    },
    /**
     * Transposes customCss object from data.json and returns as a string to be appended in the DOM
     * @param json[object] - data.json
     * @returns {string}
     */
    customCss: (json) => {
        const selectors = Object.keys(json);
        return selectors.map(function (selector) {
            const definition = json[selector];
            const rules = Object.keys(definition);
            const result = rules.map(function (rule) {
                return rule + ':' + definition[rule];
            }).join(';');
            return selector + '{' + result + '}';
        }).join('\n');
    },
    /**
     * Does browser detection based on configured unsupported browsers
     * @type function
     * @returns {boolean}
     * @constructor
     */
    ieChecker: () => {
        const ua = navigator.userAgent;
        return Config.unsupportedBrowsers.test(ua);
    },
    /**
     * If true, uses native scrolling instead of Fullpage.js scroller library
     * @param data
     */
    addIPScrollClass: (data) => {
        if (!data || !data.config || !data.config.useNativeScroll || !!data.config.disableFullpageScrolling) {
            return '';
        } else {
            $(window).on('load', addIPClass());
        }

        function addIPClass() {
            const sections = document.getElementsByClassName('section');

            for (let i = 0; i < sections.length; i++) {
                const section = sections[i];
                const windowHeight = window.innerHeight;
                const sectionHeight = section.scrollHeight;
                const hasScroll = sectionHeight > windowHeight + 20;

                if (hasScroll) {
                    section.classList.add('ip-scrollable');
                }
            }
        }
    }
};

/**
 * Creates the navigation for the directors template
 * @type function
 * @param directors[object] - Collection of director details
 * @returns {{committeeList: *[], nav: *[]}}
 */
const createNav = (directors) => {
    const leadership = directors.filter((ls) => ls.leadership);
    const committee = directors.map((director) => director.committees);
    const committeeArr = [].concat.apply([], committee).map((x) => x.name);
    const tenureArr = [].concat.apply([], directors).map((x) => x.tenure);
    const tenure = (tenureArr || []).filter((item, pos) => {
        return tenureArr.indexOf(item) === pos && item !== "";
    }).sort();
    const uniqueCommittees = (committeeArr || []).filter((item, pos) => {
        return committeeArr.indexOf(item) === pos;
    });

    return {
        committeeList: uniqueCommittees,
        nav: [{
            name: "Leadership",
            items: (leadership || []).map(ls => ls.leadership)
        }, {
            name: "Committees",
            items: uniqueCommittees
        }, {
            name: "Independence",
            items: ['Yes', 'No']
        }, {
            name: "Tenure",
            items: tenure.filter((tn, i) => tenure.indexOf(tn) === i).sort()
        }, {
            name: "Age",
            items: ['1-39', '40-49', '50-59', '60-69', '70-79', '80+']
        }]
    }
};

/**
 * Checks if site is rendered via 'phone', 'tablet', 'desktop' based on configuration and returned user device
 * @type function
 * @returns {string}
 */
function checkResponsive(width, height, dbOn) {
    const responsivePattern = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    const isResponsive = window.innerWidth <= width;
    const isMobileHeight = window.innerHeight <= height;
    const isPhone = window.innerWidth <= dbOn;
    return responsivePattern.test(navigator.userAgent) || isResponsive || isMobileHeight ? (isPhone ? 'phone' : 'tablet') : 'desktop';
}